import { LeftOutlined, LoadingOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio, Calendar, Typography, Flex } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getCustomers, getDataFilterUI, getTreeLines } from '../../../api/ui/main';
import { exportKHSX, getListProductPlan } from '../../../api';
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import "./maintenance.css"
import { getMaintenancePlans, getMaintenancePlansImport } from '../../../api/phase2/ui/maintenance';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import { maintenanceQuicker } from '../../../routes/quickerCollection';

dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

const Maintenance = () => {
    const [listLines, setListLines] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const [dateType, setDateType] = useState('date');
    useEffect(() => {
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
    }, [])

    function btn_click() {
        // loadListTable(params)
        const line_id = params?.line_id ?? null;
        fetchMaintenancePlans({line_id});
    }

    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        // setLoading(true)
        // const res = await getMaintenancePlans(params);
        // console.log(res.data);
        // setData(res.data);
        // setLoading(false)
    }

    useEffect(() => {
        btn_click()
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const [loading, setLoading] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const exportRecord = async () => {
        setLoadingExport(true);
        const res = await exportKHSX(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    const renderQuicker = () => {
        const className = (path) => {
            if (window.location.pathname.indexOf(path) > -1) {
                return 'active';
            } else {
                return 'inactive';
            }
        }
        return (
            <ul>
                {maintenanceQuicker.map(e => {
                    return (
                        <li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
                    )
                })}
            </ul>
        )
    }

    const [seletedMonth, setSeletedMonth] = useState(dayjs());
    const [openDatepicker, setOpenDatepicker] = useState(false);
    const fetchMaintenancePlans = async (params = null) => {
        setLoading(true);
        const res = await getMaintenancePlans({ date: seletedMonth, ...params });
        setData(res.data);
        setLoading(false);
    }
    useEffect(() => {
        fetchMaintenancePlans()
    }, [seletedMonth]);

    const generateCalendarData = () => {
        const startOfMonth = seletedMonth.startOf('month');
        const endOfMonth = seletedMonth.endOf('month');
        const startOfWeek = startOfMonth.isoWeekday(1);
        const endOfWeek = endOfMonth.isoWeekday(7);

        let currentWeekStart = startOfWeek;
        const dataSource = [];
        let weekCounter = seletedMonth.week();

        while (currentWeekStart.isBefore(endOfWeek)) {
            const weekData = {
                key: weekCounter,
                week: `Tuần ${weekCounter}`,
                monday: { date: currentWeekStart },
                tuesday: { date: currentWeekStart.add(1, 'day') },
                wednesday: { date: currentWeekStart.add(2, 'day') },
                thursday: { date: currentWeekStart.add(3, 'day') },
                friday: { date: currentWeekStart.add(4, 'day') },
                saturday: { date: currentWeekStart.add(5, 'day') },
                sunday: { date: currentWeekStart.add(6, 'day') },
            };
            dataSource.push(weekData);
            currentWeekStart = currentWeekStart.add(1, 'week');
            weekCounter++;
        }

        return dataSource;
    };

    const dataSource = generateCalendarData();
    const customRenderCell = (cellValue) => {
        const columns = [
            {
                title: 'CĐ',
                dataIndex: 'line_name',
                align: 'center',
            },
            {
                title: 'Máy',
                dataIndex: 'machine_code',
                align: 'center',
            },
            {
                title: 'KH',
                dataIndex: 'plan',
                align: 'center',
            },
            {
                title: 'Còn lại',
                dataIndex: 'remain',
                align: 'center',

            },
            {
                title: 'KQ',
                dataIndex: 'done',
                align: 'center',
            },
        ]
        const dateString = cellValue.date.format('YYYY-MM-DD');
        const dataSource = data[dateString] || [];
        const isDateInSelectedMonth = !(cellValue.date.isBefore(seletedMonth.startOf('month')) || cellValue.date.isAfter(seletedMonth.endOf('month')));
        return (
            <div>
                <div className='date-header' style={{ opacity: isDateInSelectedMonth ? 1 : 0.5 }}>{cellValue.date.date()}</div>
                {dataSource.length > 0 && <Table pagination={false} bordered columns={columns} dataSource={dataSource} style={{ marginTop: 28, width: 200 }} size='small' className='maintenance-table' />}
            </div>
        )
    }
    const columnCalendar = [
        {
            title: 'Tuần',
            dataIndex: 'week',
            key: 'week',
            align: 'center',
            width: 100,
        },
        {
            title: 'T2',
            dataIndex: 'monday',
            key: 'monday',
            align: 'center',
            render: (value) => customRenderCell(value),
        },
        {
            title: 'T3',
            dataIndex: 'tuesday',
            key: 'tuesday',
            align: 'center',
            render: (value) => customRenderCell(value)
        },
        {
            title: 'T4',
            dataIndex: 'wednesday',
            key: 'wednesday',
            align: 'center',
            render: (value) => customRenderCell(value)
        },
        {
            title: 'T5',
            dataIndex: 'thursday',
            key: 'thursday',
            align: 'center',
            render: (value) => customRenderCell(value)
        },
        {
            title: 'T6',
            dataIndex: 'friday',
            key: 'friday',
            align: 'center',
            render: (value) => customRenderCell(value)
        },
        {
            title: 'T7',
            dataIndex: 'saturday',
            key: 'saturday',
            align: 'center',
            render: (value) => customRenderCell(value)
        },
        {
            title: 'CN',
            dataIndex: 'sunday',
            key: 'sunday',
            align: 'center',
            render: (value) => customRenderCell(value)
        },
    ];

    const [fileList, setFileList] = useState([]);
    const [formUpload] = Form.useForm();
    const handleUpload = (values) => {
        delete values.file;
        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append('file', file);
        });
        Object.keys(values).forEach(key => {
            values[key] && formData.append(key, values[key]);
        });
        setLoadingImport(true);
        getMaintenancePlansImport(formData).then(res => {
            if (res.success) {
                // messageApi.success('Upload thành công');
                fetchMaintenancePlans();
                setLoadingImport(false);
                handleCloseModalUpload();
            } else {
                // messageApi.error('Upload thất bại');
            }
        });
    };
    const uploadProps = {
        maxCount: 1,
        multiple: false,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            return false;
        },
        fileList,
    };
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const handleOpenModalUpload = () => {
        setOpenModalUpload(true);
    }
    const handleCloseModalUpload = () => {
        setOpenModalUpload(false);
        formUpload.resetFields();
        setFileList([]);
    }
    return <>
        {contextHolder}
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card style={{ height: '100%' }} bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Công đoạn</Divider>
                            <Form.Item className='mb-3'>
                                {
                                    listLines.length ?
                                        <Tree
                                            defaultExpandedKeys={[2]}
                                            style={{ maxHeight: 250, overflowY: 'auto' }}
                                            checkable
                                            selectable={false}
                                            onCheck={onCheck}
                                            treeData={listLines}
                                        />
                                        :
                                        <LoadingOutlined />
                                }
                            </Form.Item>
                            <Divider>Quicker</Divider>
                            {renderQuicker()}
                        </Form>
                    </div>
                </Card>
            </Col>
            <Col span={20} className='custom-col'>
                <Card style={{ height: '100%' }} title="Kế hoạch thực hiện BTBD" styles={{ body: { paddingTop: 0 } }} extra={
                    <Space>
                        <Button onClick={handleOpenModalUpload}>Upload</Button>
                        <Button type="primary" onClick={exportRecord} loading={loadingExport}>Excel</Button>
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Flex justify='space-between'>
                            <Button type='text' onClick={() => setSeletedMonth(seletedMonth.subtract(1, 'month'))} icon={<LeftOutlined />}></Button>
                            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <span style={{ fontSize: 18, fontWeight: 700 }} onClick={() => setOpenDatepicker(!openDatepicker)}>{seletedMonth.format('MM-YYYY')}</span>
                                {/* <DatePicker picker='month' value={seletedMonth} style={{ textAlign: 'center' }} onChange={setSeletedMonth} format={"MM-YYYY"} /> */}
                                <DatePicker
                                    value={seletedMonth}
                                    open={openDatepicker}
                                    style={{ visibility: "hidden", width: 0, height: 0, padding: 0 }}
                                    placement='bottom'
                                    onChange={setSeletedMonth}
                                    picker='month'
                                    showNow
                                    onOpenChange={open => setOpenDatepicker(open)}
                                />
                            </div>
                            <Button type='text' onClick={() => setSeletedMonth(seletedMonth.add(1, 'month'))} icon={<RightOutlined />}></Button>
                        </Flex>
                        <Table 
                        pagination={false} 
                        columns={columnCalendar} 
                        dataSource={dataSource} 
                        bordered scroll={{ x: true, y: 'calc(100vh - 235px)' }} 
                        size='small' 
                        className='table-calendar' 
                        />
                    </Spin>
                    <Modal title="Upload kế hoạch BTBD" open={openModalUpload} onCancel={handleCloseModalUpload}
                        onOk={formUpload.submit} width={400}>
                        <Form layout='vertical' form={formUpload} onFinish={handleUpload}>
                            <Form.Item label="Ngày bắt đầu" name="start_date">
                                <DatePicker className='w-100' />
                            </Form.Item>
                            <Form.Item label="Ngày kết thúc" name="end_date">
                                <DatePicker className='w-100' />
                            </Form.Item>
                            <Form.Item label="File" name="file" rules={[{ required: true }]}>
                                <Upload {...uploadProps}>
                                    <Button icon={<UploadOutlined />}>Chọn File</Button>
                                </Upload>
                            </Form.Item>
                        </Form>
                    </Modal>
                </Card>
            </Col>
        </Row>
    </>
}

export default Maintenance;
