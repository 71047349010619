import { DeleteOutlined, EditOutlined, UploadOutlined, PlusOutlined, FileExcelOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio, InputNumber } from 'antd';
import { Pie } from '@ant-design/charts';
import { api, baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getDataFilterUI, getTreeLines } from '../../../api/ui/main';
import { deleteRecordProductPlan, exportKHSX, getListProductPlan, storeProductPlan, updateProductPlan } from '../../../api';
import dayjs from "dayjs";
import { getTreeSelect } from '../../../api/phase2/ui/main';
import { createProductOrders, deleteMultipleProductOrders, getProductOrders, importProductOrders, updateProductOrders } from '../../../api/phase2/master_data/product-order';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { getCustomer } from '../../../api/ui/customer';
import { getProduct } from '../../../api/ui/product';
import axios from 'axios';
import { manufatureQuicker } from '../../../routes/quickerCollection';


const Orders = () => {
    const [listLines, setListLines] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [listCustomers, setListCustomers] = useState([]);
    const [listCheck, setListCheck] = useState([]);
    const [openMdlEdit, setOpenMdlEdit] = useState(false);
    const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
    const [form] = Form.useForm();
    const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
    const [dateType, setDateType] = useState('date');

    const [isEdit, setIsEdit] = useState(false);
    const [openMdl, setOpenMdl] = useState(false);
    const [productOptions, setProductOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [editingRecord, setEditingRecord] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const history = useHistory();
    const onChangeChecbox = (e) => {
        if (e.target.checked) {
            if (!listCheck.includes(e.target.value)) {
                setListCheck(oldArray => [...oldArray, e.target.value]);
            }
        } else {
            if (listCheck.includes(e.target.value)) {
                setListCheck(oldArray => oldArray.filter((datainput) => datainput !== e.target.value))
            }
        }
    }
    const columns = [
        { title: 'STT', dataIndex: 'stt', key: 'stt', align: 'center', width: '45px', render: (value, record, index) => ((page - 1) * pageSize) + index + 1 },
        { title: 'ID', dataIndex: 'id', key: 'id', align: 'center', width: '100px' },
        { title: 'Ngày đặt hàng', dataIndex: 'order_date', align: 'center', width: '100px', render: (value) => dayjs(value).format('DD/MM/YYYY') },
        { title: 'Mã hàng hoá', dataIndex: 'product_id', key: 'product_id', align: 'center', width: '90px' },
        { title: 'Tên hàng hoá', dataIndex: 'product_id', key: 'product_id', align: 'center', width: '200px' },
        { title: 'Quy cách', dataIndex: 'quy_cach', key: 'quy_cach', align: 'center', width: '100px', render: (value) => value ?? 'Túi giấy ' },
        { title: 'ĐTV', dataIndex: 'unit', key: 'unit', align: 'center', width: '100px', render: (_, record) => record?.product?.unit?.name ?? 'Túi' },
        { title: 'Nợ đơn hàng', dataIndex: 'dept', key: 'dept', align: 'center', width: '100px', render: (value) => value ?? '0' },
        { title: 'Số lượng ĐH mới', dataIndex: 'new_order_quanity', key: 'new_order_quanity', align: 'center', width: '120px', render: (value) => value ?? '0' },
        { title: 'Ngày giao', dataIndex: 'delivery_date', align: 'center', width: '100px', render: (value) => value ? dayjs(value).format('DD/MM/YYYY') : '-' },
        { title: 'Tồn công đoạn', dataIndex: 'ton_cong_doan', align: 'center', 
            children: [
                { title: 'Gấp dán', dataIndex: 'gap_dan', align: 'center', width: '100px', render: (value) => value ?? '0' }, 
                { title: 'In', dataIndex: 'in', align: 'center', width: '100px', render: (value) => value ?? '0' }, 
                { title: 'Ghép Inner', dataIndex: 'ghep_inner', align: 'center', width: '100px', render: (value) => value ?? '0' }, 
                { title: 'Bế', dataIndex: 'be', align: 'center', width: '100px', render: (value) => value ?? '0' }, 
                { title: 'Chọn', dataIndex: 'chon', align: 'center', width: '100px', render: (value) => value ?? '0' }
            ] 
        },
        { title: 'Tồn kho TP', dataIndex: 'ton_kho_tp', key: 'ton_kho_tp', align: 'center', width: '100px', render: (value) => value ?? '-' },
        { title: 'SL sản xuất theo FC', dataIndex: 'sl_sx_fc', key: 'sl_sx_fc', align: 'center', width: '100px', render: (value) => value ?? '-' },
        { title: 'SL sản xuất theo ĐH', dataIndex: 'sl_sx_dh', key: 'sl_sx_dh', align: 'center', width: '100px', render: (value) => value ?? '-' },
        { title: 'Tổng SL sản xuất', dataIndex: 'sum_sl_sx', key: 'sum_sl_sx', align: 'center', width: '100px', render: (value) => value ?? '-' },
        { title: 'Ghi chú', dataIndex: 'note', key: 'note', align: 'left', width: '300px', render: (value) => String(value ?? '').slice(0, 45) + '...' }
    ];
    
    useEffect(() => {
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));
        })()
    }, [])

    function btn_click(page = 1, pageSize = 20) {
        setPage(page);
        setPageSize(pageSize)
        loadListTable({ ...params, page, pageSize });
    }

    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
            }
        })()
    }, [params.khach_hang])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        // const res = await getListProductPlan(params);
        // setTotalPage(fakedata.length)
        // setData(fakedata);
        params.withs = 'product.unit';
        const res = await getProductOrders(params);
        setData((res.data?.data ?? []).map(e => {
            return { ...e, key: e.id }
        }));
        setTotalPage(res.data.total);
        setLoading(false)
    }
    useEffect(() => {
        (async () => {
            loadListTable(params);
        })()
    }, [])

    const [messageApi, contextHolder] = message.useMessage();

    const success = () => {
        messageApi.open({
            type: 'success',
            content: 'Upload file thành công',
        });
    };

    const error = () => {
        messageApi.open({
            type: 'error',
            content: 'Upload file lỗi',
        });
    };

    // const onFinish = async (values) => {
    //     if (values.id) {
    //         const res = await updateProductPlan(values);
    //     } else {
    //         const res = await storeProductPlan(values);
    //     }
    //     setOpenMdlEdit(false);
    //     loadListTable(params);
    // }

    const onFinish = async (values) => {
        if (values?.order_date) values.order_date = dayjs(values.order_date).format('YYYY-MM-DD');
        if (values?.delivery_date) values.delivery_date = dayjs(values.delivery_date).format('YYYY-MM-DD');
        if (isEdit) {
            const res = await updateProductOrders(editingRecord?.id, values);
            if (res.success) {
                form.resetFields();
                setOpenMdl(false);
                setEditingRecord();
                setSelectedRowKeys([]);
            }
        } else {
            const res = await createProductOrders(values);
            if (res.success) {
                form.resetFields();
                setOpenMdl(false);
                setSelectedRowKeys([]);
            }
        }
        loadListTable(params);
    }

    const deleteRecord = async () => {
        if (selectedRowKeys.length > 0) {
            const res = await deleteMultipleProductOrders(selectedRowKeys);
            setListCheck([]);
            setSelectedRowKeys([]);
            loadListTable(params);
        } else {
            message.info('Chưa chọn bản ghi cần xóa')
        }
    }
    const editRecord = () => {
        setTitleMdlEdit('Cập nhật')
        if (selectedRowKeys.length > 1) {
            message.info('Chỉ chọn 1 bản ghi để chỉnh sửa');
        } else if (selectedRowKeys.length == 0) {
            message.info('Chưa chọn bản ghi cần chỉnh sửa')
        } else {
            // const result = data.find((record) => record.id === listCheck[0]);
            // form.setFieldsValue({
            //     id: listCheck[0], thu_tu_uu_tien: result.thu_tu_uu_tien, thoi_gian_bat_dau: result.thoi_gian_bat_dau, thoi_gian_ket_thuc: result.thoi_gian_ket_thuc,
            //     cong_doan_sx: result.cong_doan_sx, product_id: result.product_id, khach_hang: result.khach_hang, ca_sx: result.ca_sx, lo_sx: result.lo_sx, so_bat: result.so_bat, sl_nvl: result.sl_nvl,
            //     sl_thanh_pham: result.sl_thanh_pham, UPH: result.UPH, nhan_luc: result.nhan_luc
            // })
            // setOpenMdlEdit(true);
            const record = data.find((item) => item.id === selectedRowKeys[0]);
            if (record) {
                setIsEdit(true);
                if (record?.order_date) record.order_date = dayjs(record.order_date);
                if (record?.delivery_date) record.delivery_date = dayjs(record.delivery_date);
                const fields = { ...record };
                form.setFieldsValue(fields);
                setEditingRecord(fields);
                setOpenMdl(true);
            } else {
                message.error(`Record not found with id ${selectedRowKeys[0]}`);
            }
        }
    }
    const insertRecord = () => {
        // setTitleMdlEdit('Thêm mới')
        setIsEdit(false);
        form.resetFields();
        form.setFieldsValue({ order_date: dayjs() });
        // setOpenMdlEdit(true);
        setOpenMdl(true);
    }
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const exportRecord = async () => {
        setLoadingExport(true);
        const res = await exportKHSX(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setLoadingExport(false);
    }
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }

    const fetchCustomer = async () => {
        setLoading(true);
        const response = await getCustomer();
        if (response?.success) {
            setCustomerOptions((response.data?.data ?? []).map((item) => {
                return {
                    label: item?.name,
                    value: item?.id,
                }
            }))
        }
        setLoading(false);
    }

    const fetchProduct = async () => {
        setLoading(true);
        const response = await getProduct();
        if (response?.success) {
            setProductOptions((response.data?.data ?? []).map((item) => {
                return {
                    label: <><span style={{ fontWeight: 600 }}>{item?.id}</span><small style={{ color: 'gray' }}>({item?.name})</small></>,
                    value: item?.id,
                }
            }))
        }
        setLoading(false);
    }

    const renderQuicker = () => {
        console.log(window.location.pathname);
        const className = (path) => {
            if (window.location.pathname.indexOf(path) > -1) {
                return 'active';
            } else {
                return 'inactive';
            }
        }
        return (
            <ul>
                {manufatureQuicker.map(e => {
                    return (
                        <li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
                    )
                })}
            </ul>
        )
    }

    const onCancel = () => {
        form.resetFields();
        setOpenMdl(false);
    }

    useEffect(() => {
        fetchProduct();
        fetchCustomer();
    }, [])

    const rowSelection = {
        selectedRowKeys,
        onChange: setSelectedRowKeys,
    };

    return <>
        {contextHolder}
        <Row style={{ padding: '8px' }} className="custom-row" gutter={[8, 8]}>
            <Col span={4} className="custom-col">
                <Card className='custom-ant-card' style={{ height: '100%' }} bodyStyle={{ padding: 0 }} actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <div className='mb-3'>
                        <Form style={{ margin: '0 15px' }} layout="vertical">
                            <Divider>Quicker</Divider>
                            {renderQuicker()}
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                size='small'
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Khách hàng" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    onChange={(value) => setParams({ ...params, khach_hang: value })}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listCustomers}
                                />
                            </Form.Item>
                            <Form.Item label="Tên sản phẩm" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    onChange={(value) => {
                                        setParams({ ...params, ten_sp: value })
                                    }}
                                    placeholder="Nhập tên sản phẩm"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listNameProducts}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </Card>
            </Col>
            <Col className="custom-col-table" span={20}>
                <Card style={{ height: '100%' }} title="Đơn hàng" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            customRequest={async ({ file, onSuccess, onError }) => {
                                const formData = new FormData();
                                formData.append('file', file);
                                const res = await importProductOrders(formData);
                                if (res.success) {
                                    loadListTable(params);
                                    setLoadingUpload(false);
                                } else {
                                    loadListTable(params);
                                    setLoadingUpload(false);
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type='primary' loading={loadingUpload}>
                                Upload
                            </Button>
                        </Upload>
                        {/* <Button onClick={deleteRecord} danger>Delete</Button>
                        <Button onClick={editRecord}>Edit</Button>
                        <Button onClick={insertRecord}>Insert</Button>
                        <Button onClick={exportRecord} loading={loadingExport}>Report</Button>
                        <Button type="primary" onClick={runOrder}>Run</Button> */}
                    </Space>
                }>
                    <Table size='small' bordered
                        locale={{ emptyText: '' }}
                        className="table-order"
                        pagination={{
                            current: page,
                            pageSize: 20,
                            size: "default",
                            total: totalPage,
                            onChange: (page, pageSize) => {
                                btn_click(page, pageSize);
                            }
                        }}
                        scroll={{ x: '2040px', y: CalculateTableHeight('table-order') }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={data} />
                </Card>
            </Col>
        </Row>
        <Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={12} className='d-none'>
                        <Form.Item name="id" className='mb-3 d-none'>
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Thứ tự ưu tiên" name="thu_tu_uu_tien" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập thứ tự ưu tiên'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ngày sản xuất (YYYY-MM-DD)" name="ngay_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ngày sản xuất'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ngày đặt hàng (YYYY-MM-DD)" name="ngay_dat_hang" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ngày đặt hàng'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ngày giao hàng (YYYY-MM-DD)" name="ngay_giao_hang" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ngày giao hàng'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Thời gian bắt đầu (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_bat_dau" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='' ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Thời gian kết thúc (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_ket_thuc" className='mb-3' rules={[{ required: true }]}>
                            <Input ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Máy" name="machine_id" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập tên máy'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Công đoạn" name="cong_doan_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập công đoạn'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Mã sản phẩm" name="product_id" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập mã sản phẩm'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Khách hàng" name="khach_hang" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập khách hàng'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Ca sản xuất" name="ca_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập ca sản xuất'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Lô sản xuất" name="lo_sx" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập lô sản xuất'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Số bát" name="so_bat" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập số bát'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Số lượng nguyên liệu đầu vào (tờ)" name="sl_nvl" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập số lượng nguyên liệu đầu vào (tờ)'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Kế hoạch SL thành phẩm (tờ)" name="sl_thanh_pham" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhập kế hoạch SL thành phẩm (tờ)'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="UPH" name="UPH" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='UPH'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Nhân lực" name="nhan_luc" className='mb-3' rules={[{ required: true }]}>
                            <Input placeholder='Nhân lực'></Input>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>

        {/* Modal create/update */}
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={onCancel} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={5}>
                    <Col span={12}>
                        <Form.Item name={'id'} className='mb-3' label={'Mã đơn'} rules={[{ required: true }]}>
                            <Input placeholder="Mã đơn hàng" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'order_number'} className='mb-3' label={'Số đơn hàng'} rules={[{ required: true }]}>
                            <Input placeholder="Số đơn hàng" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'customer_id'} className='mb-3' label={'Khách hàng'} rules={[{ required: true }]}>
                            <Select showSearch allowClear placeholder="Chọn khách hàng" options={customerOptions} optionFilterProp='label' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'product_id'} className='mb-3' label={'Mã hàng'} rules={[{ required: true }]}>
                            <Select showSearch allowClear placeholder="Chọn mã hàng" options={productOptions} optionFilterProp='value' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'quantity'} className='mb-3' label={'Số lượng'} rules={[{ required: true }]}>
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'order_date'} className='mb-3' label={'Ngày đặt hàng'} rules={[{ required: true }]}>
                            <DatePicker showTime={false} allowClear={false} placeholder="Ngày đặt" format="DD/MM/YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'delivery_date'} className='mb-3' label={'Ngày giao'} rules={[{ required: false }]}>
                            <DatePicker showTime={false} allowClear={false} placeholder="Ngày giao" format="DD/MM/YYYY" style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'note'} className='mb-3' label={'Ghi chú thay đổi'} rules={[{ required: false }]}>
                            <Input placeholder="Ghi chú thay đổi" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default Orders;
