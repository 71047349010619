
import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import highcharts3dModule from "highcharts/modules/exporting";

highcharts3d(Highcharts);
highcharts3dModule(Highcharts);

const WastagesChart = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setData([
                    {
                        "type": "Tuần 1",
                        "value": 50,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tuần 2",
                        "value": 101,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tuần 3",
                        "value": 92,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tuần 4",
                        "value": 47,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tuần 1",
                        "value": 52,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tuần 2",
                        "value": 119,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tuần 3",
                        "value": 100,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tuần 4",
                        "value": 62,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tuần 1",
                        "value": 49,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tuần 2",
                        "value": 93,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tuần 3",
                        "value": 100,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tuần 4",
                        "value": 46,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tuần 1",
                        "value": 80,
                        "machine": "ACE70CS"
                    },
                    {
                        "type": "Tuần 2",
                        "value": 27,
                        "machine": "ACE70CS"
                    },
                    {
                        "type": "Tuần 3",
                        "value": 100,
                        "machine": "ACE70CS"
                    },
                    {
                        "type": "Tuần 4",
                        "value": 21,
                        "machine": "ACE70CS"
                    }
                ]);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const weeks = [...new Set(data.map(item => item.type))];
        const machines = [...new Set(data.map(item => item.machine))];
        const series = [];
        machines.forEach(machine => {
            series.push(
                {
                    name: machine,
                    data: weeks.map(week => {
                        const item = data.find(d => d.type === week && d.machine === machine);
                        return item ? item.value : 0;
                    }),
                },
            );
        });
        
        const options = {
            chart: {
                height: 300,
                type: 'column',
                // options3d: {
                //     enabled: true,
                //     alpha: 20,
                //     beta: 0,
                //     viewDistance: 100,
                //     depth: 100
                // }
            },

            title: false,

            xAxis: {
                categories: weeks,
                crosshair: true,
                accessibility: {
                    description: 'Máy'
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    }
                },
                borderRadius: 0,
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("wastages-chart", options);
    }, [data]);

    return <Card title="Hao phí các công đoạn"
        styles={{body: {padding: '0px'}}}
        style={
            { padding: '0px' }
        }>
        <div id="wastages-chart" />
    </Card>
};

export default WastagesChart;