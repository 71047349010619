import { DeleteOutlined, EditOutlined, UploadOutlined, PlusOutlined, FileExcelOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getDataFilterUI, getTreeLines } from '../../../api/ui/main';
import { deleteRecordProductPlan, exportKHSX, getListProductPlan, storeProductPlan, updateProductPlan } from '../../../api';
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import duration from 'dayjs/plugin/duration';
import { getTreeSelect } from '../../../api/phase2/ui/main';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { manufatureQuicker } from '../../../routes/quickerCollection';
dayjs.extend(duration);
dayjs.extend(isoWeek);
const { Sider } = Layout;
const { RangePicker } = DatePicker;

const FC = () => {
	const [listLines, setListLines] = useState([]);
	const [listNameProducts, setListNameProducts] = useState([]);
	const [listLoSX, setListLoSX] = useState([]);
	const [listCustomers, setListCustomers] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [listCheck, setListCheck] = useState([]);
	const [openMdlEdit, setOpenMdlEdit] = useState(false);
	const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
	const [form] = Form.useForm();
	const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
	const [dateType, setDateType] = useState('date');
	const onChangeChecbox = (e) => {
		if (e.target.checked) {
			if (!listCheck.includes(e.target.value)) {
				setListCheck(oldArray => [...oldArray, e.target.value]);
			}
		} else {
			if (listCheck.includes(e.target.value)) {
				setListCheck(oldArray => oldArray.filter((datainput) => datainput !== e.target.value))
			}
		}
	}
	const columns = [
		{
			title: 'STT',
			dataIndex: 'stt',
			key: 'stt',
			render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
			align: 'center',
			width: '70px'
		},
		{
			title: 'Chọn',
			dataIndex: 'name1',
			key: 'name1',
			render: (value, item, index) => <Checkbox value={item.id} onChange={onChangeChecbox}></Checkbox>,
			align: 'center',
			width: '70px'
		},
		{
			title: 'Plant',
			dataIndex: 'plant',
			align: 'center',
			width: '100px'
		},
		{
			title: 'Plan Name',
			dataIndex: 'plant_name',
			align: 'center',
			width: '200px'
		},
		{
			title: 'material',
			dataIndex: 'material',
			align: 'center',
			width: '200px'
		},
		{
			title: 'MODEL',
			dataIndex: 'model',
			align: 'center',
			width: '100px'
		},
		{
			title: 'PO hiện tại đến (W23 trong nước) (W28 nước ngoài)',
			dataIndex: 'curent_po',
			align: 'center',
			width: '250px'
		},
		{
			title: 'Tổng FC',
			dataIndex: 'total_fc',
			align: 'center',
			width: '100px'
		},
	];

	const [additiionalColumns, setAdditiionalColumns] = useState([]);
	useEffect(() => {
		(async () => {
			const res1 = await getTreeSelect();
			setListLines(res1.data);
			const res5 = await getCustomers();
			setListCustomers(res5.data.map(e => {
				return { ...e, label: e.name, value: e.id }
			}));
		})()
	}, [])

	function btn_click(page = 1, pageSize = 20) {
		setPage(page);
		setPageSize(pageSize);
		loadListTable(params);
	}

	useEffect(() => {
		(async () => {
			var res = await getDataFilterUI({ khach_hang: params.khach_hang });
			if (res.success) {
				setListNameProducts(res.data.product.map(e => {
					return { ...e, label: e.name, value: e.id }
				}));
				setListLoSX(Object.values(res.data.lo_sx).map(e => {
					return { label: e, value: e }
				}));
			}
		})()
	}, [params.khach_hang]);

	const handleAddColumns = () => {
		var startWeek = params.date[0].isoWeek();
		var endtWeek = params.date[1].isoWeek();
		const weekColumns = [];
		for (let index = 0; index <= (endtWeek - startWeek); index++) {
			var startOfWeek = params.date[0].startOf('isoWeek').add(index, 'weeks');
			weekColumns.push({
				title: 'W' + startOfWeek.week() + '(' + startOfWeek.format('DD/MM') + ')',
				dataIndex: 'W' + startOfWeek.week(),
				align: 'center',
			});
		}
		setAdditiionalColumns(weekColumns);
		console.log([...columns, ...additiionalColumns]);
	}

	const [data, setData] = useState([]);
	const loadListTable = async (params) => {
		setLoading(true)
		const res = await getListProductPlan(params);
		setData(
			[
				{
					"stt": 1,
					"name1": "",
					"plant": "P518",
					"plant_name": "[SEV]Mobile Plant",
					"material": "GH69-43593B",
					"model": "A155",
					"curent_po": 1866781,
					"total_fc": 34846537,
					"W22": "",
					"W23": 2105414,
					"W24": 2301694,
					"W25": 1831362,
					"W26": 1371134,
					"W27": 1789080,
					"W28": 1635605,
					"W29": 1654015,
					"W30": 1579375,
					"W31": 2125455,
					"W32": 2036000
				},
				{
					"stt": 2,
					"name1": "",
					"plant": "P811",
					"plant_name": "[SEDA]Mobile Campinas",
					"material": "GH69-43593C",
					"model": "A155",
					"curent_po": 8263,
					"total_fc": 148923,
					"W22": 8723,
					"W23": 8500,
					"W24": 5500,
					"W25": 2400,
					"W26": 12400,
					"W27": 7100,
					"W28": 1900,
					"W29": 3000,
					"W30": 19500,
					"W31": 15000,
					"W32": 12400
				},
				{
					"stt": 3,
					"name1": "",
					"plant": "P81J",
					"plant_name": "[SEDA]JHP Manaus",
					"material": "GH69-43593C",
					"model": "A155",
					"curent_po": 341000,
					"total_fc": 989360,
					"W22": 39560,
					"W23": 54240,
					"W24": 48330,
					"W25": 37490,
					"W26": "",
					"W27": "",
					"W28": 39690,
					"W29": 37350,
					"W30": 42800,
					"W31": 40170,
					"W32": 46750
				},
				{
					"stt": 4,
					"name1": "",
					"plant": "P529",
					"plant_name": "[SEIN]JHP Plant",
					"material": "GH69-43295A",
					"model": "A156",
					"curent_po": 300557,
					"total_fc": 300557,
					"W22": 15094,
					"W23": 31542,
					"W24": 41386,
					"W25": 29360,
					"W26": 14550,
					"W27": 17875,
					"W28": 18875,
					"W29": 20235,
					"W30": 20750,
					"W31": 9050,
					"W32": 22950
				},
				{
					"stt": 5,
					"name1": "",
					"plant": "P538",
					"plant_name": "[SIEL]Mobile Noida",
					"material": "GH69-43295A",
					"model": "A156",
					"curent_po": 959042,
					"total_fc": 959042,
					"W22": 101820,
					"W23": 124178,
					"W24": 45942,
					"W25": 54200,
					"W26": 38890,
					"W27": 64480,
					"W28": 43380,
					"W29": 28080,
					"W30": 28800,
					"W31": 51660,
					"W32": 73200
				},
				{
					"stt": 6,
					"name1": "",
					"plant": "P518",
					"plant_name": "[SEV]Mobile Plant",
					"material": "GH69-43295A",
					"model": "A156",
					"curent_po": 1525681,
					"total_fc": 1463645,
					"W22": 87063,
					"W23": 168429,
					"W24": 115160,
					"W25": 140030,
					"W26": 86043,
					"W27": 101290,
					"W28": 108530,
					"W29": 101460,
					"W30": 39060,
					"W31": 81120,
					"W32": 97340
				},
				{
					"stt": 7,
					"name1": "",
					"plant": "P520",
					"plant_name": "[SEVT]Mobile Plant",
					"material": "GH69-43295A",
					"model": "A156",
					"curent_po": 9574172,
					"total_fc": 9574172,
					"W22": 362770,
					"W23": 636717,
					"W24": 629995,
					"W25": 541030,
					"W26": 475141,
					"W27": 596940,
					"W28": 529200,
					"W29": 446160,
					"W30": 481470,
					"W31": 730520,
					"W32": 561470
				},
				{
					"stt": 8,
					"name1": "",
					"plant": "P520",
					"plant_name": "[SEVT]Mobile Plant",
					"material": "GH69-43295C",
					"model": "A156",
					"curent_po": 100,
					"total_fc": 43290,
					"W22": 1500,
					"W23": 600,
					"W24": 1200,
					"W25": 1800,
					"W26": 1800,
					"W27": 1800,
					"W28": 1800,
					"W29": 1500,
					"W30": 1500,
					"W31": 1500,
					"W32": 1500
				}
			]
		);
		handleAddColumns();
		setLoading(false);
	}
	useEffect(() => {
		(async () => {
			loadListTable(params);
		})()
	}, [])

	const [messageApi, contextHolder] = message.useMessage();

	const success = () => {
		messageApi.open({
			type: 'success',
			content: 'Upload file thành công',
		});
	};

	const error = () => {
		messageApi.open({
			type: 'error',
			content: 'Upload file lỗi',
		});
	};

	const onFinish = async (values) => {
		if (values.id) {
			const res = await updateProductPlan(values);
		} else {
			const res = await storeProductPlan(values);
		}
		setOpenMdlEdit(false);
		loadListTable(params);
	}

	const deleteRecord = async () => {
		if (listCheck.length > 0) {
			const res = await deleteRecordProductPlan(listCheck);
			setListCheck([]);
			loadListTable(params);
		} else {
			message.info('Chưa chọn bản ghi cần xóa')
		}
	}
	const editRecord = () => {
		setTitleMdlEdit('Cập nhật')
		if (listCheck.length > 1) {
			message.info('Chỉ chọn 1 bản ghi để chỉnh sửa');
		} else if (listCheck.length == 0) {
			message.info('Chưa chọn bản ghi cần chỉnh sửa')
		} else {
			const result = data.find((record) => record.id === listCheck[0]);
			form.setFieldsValue({
				id: listCheck[0], thu_tu_uu_tien: result.thu_tu_uu_tien, thoi_gian_bat_dau: result.thoi_gian_bat_dau, thoi_gian_ket_thuc: result.thoi_gian_ket_thuc,
				cong_doan_sx: result.cong_doan_sx, product_id: result.product_id, khach_hang: result.khach_hang, ca_sx: result.ca_sx, lo_sx: result.lo_sx, so_bat: result.so_bat, sl_nvl: result.sl_nvl,
				sl_thanh_pham: result.sl_thanh_pham, UPH: result.UPH, nhan_luc: result.nhan_luc
			})
			setOpenMdlEdit(true);
		}
	}
	const insertRecord = () => {
		setTitleMdlEdit('Thêm mới')
		form.resetFields();
		setOpenMdlEdit(true);
	}
	const [loadingUpload, setLoadingUpload] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingExport, setLoadingExport] = useState(false);
	const exportRecord = async () => {
		setLoadingExport(true);
		const res = await exportKHSX(params);
		if (res.success) {
			window.location.href = baseURL + res.data;
		}
		setLoadingExport(false);
	}
	const onCheck = (checkedKeys, info) => {
		const selectKeys = [];
		if (info.node.type === 'factory') {
			if (info.checked) {
				info.node.children.map(e => {
					selectKeys.push(e.key?.toString())
				})
			}
		} else {
			info.checkedNodes.map(e => {
				switch (e.type) {
					case 'line':
						selectKeys.push(e.key?.toString());
						break;
					case 'machine':
						selectKeys.push(e.line_id?.toString());
						break;
					default:
						break;
				}
			})
		}
		setParams({ ...params, line_id: [...new Set(selectKeys)] })
	};
	const customDateFormat = (value) => {
		switch (dateType) {
			case 'week':
				return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
				break;
			case 'month':
				return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
				break;
			case 'year':
				return (`Năm ${dayjs(value).format('YYYY')}`);
				break;
			default:
				return (dayjs(value).format('DD/MM/YYYY'));
				break;
		}
	}
	useEffect(()=>{
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
	const onChangeDate = (position, value, dateType) => {
		switch (dateType) {
			case 'week':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
				}
				break;
			case 'month':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
				}
				break;
			case 'year':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
				}
				break;
			default:
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
				}
				break;
		}
	}
	const renderQuicker = () => {
		const className = (path) => {
			if (window.location.pathname.indexOf(path) > -1) {
				return 'active';
			} else {
				return 'inactive';
			}
		}
		return (
			<ul>
				{manufatureQuicker.map(e => {
					return (
						<li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
					)
				})}
			</ul>
		)
	}
	return <>
		{contextHolder}
		<Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
			<Col span={4} className='custom-col'>
				<Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
					<Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
				]}>
					<Form style={{ margin: '0 8px' }} layout="vertical">
						<Divider>Công đoạn</Divider>
						<Form.Item className='mb-3'>
							{
								listLines.length ?
									<Tree
										defaultExpandedKeys={[2]}
										style={{ maxHeight: 250, overflowY: 'auto' }}
										checkable
										selectable={false}
										onCheck={onCheck}
										treeData={listLines}
									/>
									:
									<LoadingOutlined />
							}
						</Form.Item>
						<Divider>Quicker</Divider>
						{renderQuicker()}
						<Divider>Thời gian truy vấn</Divider>
						<Radio.Group
							options={[
								{ value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
							]}
							className='d-flex justify-content-center mb-2 w-100'
							onChange={(e) => setDateType(e.target.value)}
							value={dateType}
							optionType="button"
							buttonStyle="solid"
						/>
						<Space direction='vertical' style={{ width: '100%' }}>
							<DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
							<DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
						</Space>
						<Divider>Điều kiện truy vấn</Divider>
						<Form.Item label="Khách hàng" className='mb-3'>
							<Select
								allowClear
								showSearch
								placeholder="Chọn khách hàng"
								onChange={(value) => setParams({ ...params, khach_hang: value })}
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
								options={listCustomers}
							/>
						</Form.Item>
						<Form.Item label="Tên sản phẩm" className='mb-3'>
							<Select
								allowClear
								showSearch
								onChange={(value) => {
									setParams({ ...params, ten_sp: value })
								}}
								placeholder="Nhập tên sản phẩm"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={listNameProducts}
							/>
						</Form.Item>
					</Form>
				</Card>
			</Col>
			<Col span={20} className="custom-col-table">
				<Card style={{ height: '100%' }} title="FC" extra={
					<Space>
						<Upload
							showUploadList={false}
							name='files'
							action={baseURL + "/api/upload-ke-hoach-san-xuat"}
							headers={{
								authorization: 'authorization-text',
							}}
							onChange={(info) => {
								setLoadingUpload(true);
								if (info.file.status === 'error') {
									setLoadingUpload(false);
									error()
								} else if (info.file.status === 'done') {
									if (info.file.response.success === true) {
										loadListTable(params);
										success();
										setLoadingUpload(false);
									} else {
										loadListTable(params);
										message.error(info.file.response.message);
										setLoadingUpload(false);
									}
								}
							}}
						>
							<Button style={{ marginLeft: '15px' }} type="primary" loading={loadingUpload}>
								Upload Excel
							</Button>
						</Upload>
						<Button type="primary" onClick={exportRecord} loading={loadingExport}>Export Excel</Button>
						<Button type="primary" onClick={deleteRecord} danger>Delete</Button>
						<Button type="primary" onClick={editRecord}>Edit</Button>
						<Button type="primary" onClick={insertRecord}>Insert</Button>
					</Space>
				}>
					<Spin spinning={loading}>
						<Table size='small' bordered
							locale={{emptyText: ''}}
							className="table-fc"
							loading={loading}
							pagination={{
								current: page,
								pageSize: pageSize,
								size: "default",
								total: totalPage,
								onChange: (page, pageSize) => {
									btn_click(page, pageSize);
								}
							}}
							scroll={{x: `${1090 + (additiionalColumns.length * 100)}px`, y: CalculateTableHeight('table-fc')}}
							columns={[...columns, ...additiionalColumns]}
							dataSource={data} />
					</Spin>
				</Card>
			</Col>
		</Row>
		<Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null} width={800}>
			<Form style={{ margin: '0 15px' }}
				layout="vertical"
				form={form}
				onFinish={onFinish}>
				<Row gutter={[16, 16]}>
					<Col span={12} className='d-none'>
						<Form.Item name="id" className='mb-3 d-none'>
							<Input></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Thứ tự ưu tiên" name="thu_tu_uu_tien" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập thứ tự ưu tiên'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ngày sản xuất (YYYY-MM-DD)" name="ngay_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ngày sản xuất'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ngày đặt hàng (YYYY-MM-DD)" name="ngay_dat_hang" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ngày đặt hàng'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ngày giao hàng (YYYY-MM-DD)" name="ngay_giao_hang" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ngày giao hàng'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Thời gian bắt đầu (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_bat_dau" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='' ></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Thời gian kết thúc (YYYY-MM-DD HH:mm:ss)" name="thoi_gian_ket_thuc" className='mb-3' rules={[{ required: true }]}>
							<Input ></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Máy" name="machine_id" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập tên máy'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Công đoạn" name="cong_doan_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập công đoạn'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Mã sản phẩm" name="product_id" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập mã sản phẩm'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Khách hàng" name="khach_hang" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập khách hàng'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Ca sản xuất" name="ca_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập ca sản xuất'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Lô sản xuất" name="lo_sx" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập lô sản xuất'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Số bát" name="so_bat" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập số bát'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Số lượng nguyên liệu đầu vào (tờ)" name="sl_nvl" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập số lượng nguyên liệu đầu vào (tờ)'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Kế hoạch SL thành phẩm (tờ)" name="sl_thanh_pham" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhập kế hoạch SL thành phẩm (tờ)'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="UPH" name="UPH" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='UPH'></Input>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Nhân lực" name="nhan_luc" className='mb-3' rules={[{ required: true }]}>
							<Input placeholder='Nhân lực'></Input>
						</Form.Item>
					</Col>
				</Row>
				<Form.Item className='mb-0'>
					<Button type="primary" htmlType='submit' >Lưu lại</Button>
				</Form.Item>
			</Form>
		</Modal>
	</>
}

export default FC;
