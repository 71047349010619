import { DeleteOutlined, EditOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Divider, Form, Input, InputNumber, Modal, Popconfirm, QRCode, Row, Select, Space, Spin, Table, Upload, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { createTemplate, deleteTemplate, deleteMultipleTemplate, exportTemplate, getTemplate, updateTemplate } from '../../../api/ui/template';
import { api, baseURL } from '../../../config';
import dayjs from 'dayjs';
import './MasterDataStyle.css';
import { getCustomer } from '../../../api/ui/customer';
import { getMaterial } from '../../../api/ui/material';
import ReactToPrint from 'react-to-print';

const Template = () => {
    document.title = "Quản lý Tem";
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [formSearch] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [templateOptions, setTemplateOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [showQrModal, setShowQrModal] = useState(false);
    const [currentQr, setCurrentQr] = useState(null);
    const printRef = useRef(null);
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            align: 'center',
            width: '50px',
        },
        {
            title: 'Mã NVL',
            dataIndex: 'material_id',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Ngày sản xuất',
            dataIndex: 'manufacture_date',
            align: 'center',
            width: '100px',
            render: (value) => value ? dayjs(value).format('DD/MM/YYYY') : '',
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Số cuộn',
            dataIndex: 'roll_quantity',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Số máy',
            dataIndex: 'machine_number',
            align: 'center',
            width: '100px',
        },
        {
            title: 'Tên công nhân',
            dataIndex: 'worker_name',
            align: 'center',
            width: '120px',
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            align: 'center',
            fixed: 'right',
            width: '150px',
            render: (_, record) => (
                <Space wrap>
                    <QrcodeOutlined className='edit-btn' onClick={() => handlePrintQr(record)} />
                    {/* <EditOutlined className='edit-btn' onClick={() => editRecord(record)} /> */}
                    {/* <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={() => deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm> */}
                </Space>
            )
        },
    ];
    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadListTable({ ...formSearch.getFieldsValue(true), page, pageSize });
    }

    useEffect(() => {
        fetchTemplate();
        fetchCustomer();
        btn_click();
    }, [])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setSelectedRowKeys([]);
        setLoading(true)
        const res = await getTemplate(params);
        setData((res.data?.data ?? []).map(e => {
            return { ...e, key: e.id }
        }));
        console.log(res.data);
        setTotal(res.data.total)
        setLoading(false);
    }

    const onFinish = async (values) => {
        if (values?.order_date) values.order_date = dayjs(values.order_date).format('YYYY-MM-DD');
        if (values?.delivery_date) values.delivery_date = dayjs(values.delivery_date).format('YYYY-MM-DD');
        if (isEdit) {
            const res = await updateTemplate(editingRecord?.id, values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                setEditingRecord();
            }
        } else {
            const res = await createTemplate(values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
            }
        }
        btn_click();
    }

    const deleteRecord = async (record) => {
        const res = await deleteTemplate(record.id);
        btn_click();
    }
    const deleteMultiple = async () => {
        console.log(selectedRowKeys);
        const res = await deleteMultipleTemplate(selectedRowKeys);
        btn_click();
    }
    const editRecord = (record) => {
        setIsEdit(true);
        if (record?.order_date) record.order_date = dayjs(record.order_date);
        if (record?.delivery_date) record.delivery_date = dayjs(record.delivery_date);
        const fields = { ...record }
        form.setFieldsValue(fields);
        setEditingRecord(fields);
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        form.setFieldsValue({ order_date: dayjs() })
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportTemplate({ ...formSearch.getFieldsValue(true) });
        if (res.success && res.data) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
    };
    const onCancel = () => {
        form.resetFields();
        setOpenMdl(false);
    }

    const fetchTemplate = async () => {
        setLoading(true);
        const response = await getTemplate();
        if (response?.success) {
            setTemplateOptions((response.data?.data ?? []).map((item) => {
                return {
                    label: <><span style={{ fontWeight: 600 }}>{item?.id}</span><small style={{ color: 'gray' }}>({item?.name})</small></>,
                    value: item?.id,
                }
            }))
        }
        setLoading(false);
    }

    const fetchCustomer = async () => {
        setLoading(true);
        const response = await getMaterial();
        if (response?.success) {
            setCustomerOptions((response.data?.data ?? []).map((item) => {
                return {
                    label: item?.name,
                    value: item?.id,
                }
            }))
        }
        setLoading(false);
    }

    const htmlQr = (key = 0) => {
        return (
            <div style={{ padding: '30px' }}>
                <div className="print-template" key={key} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', maxWidth: '100mm', maxHeight: '75mm', padding: '5px' }}>
                    <table className='tableTemplate' style={{ border: '1px solid black', width: '100mm', height: '75mm' }}>
                        <tbody>
                            <tr>
                                {/* <td colSpan={2}><div style={{ display: 'flex' }}>
                                    <div>
                                        <QRCode value={currentQr?.material_id} size={80} bordered={false} style={{ padding: '0px', margin: '0px', marginLeft: '10px' }} />
                                    </div>
                                    <div style={{ float: 'right' }}><h4>Tem cuộn</h4></div>
                                </div>
                                </td> */}
                                <td colSpan={2} style={{ height: '150px' }}>
                                    <div className="d-flex justify-content-between mt-0 mb-0" style={{ padding: '0px', margin: '0px' }}>
                                        <div className="flex-column" style={{ padding: '0px', margin: '0px' }}>
                                            <h5 style={{ marginLeft: '8px', marginBottom: '15px' }}>Nguyên vật liệu</h5>
                                            <h4 style={{ marginLeft: '8px' }}>{currentQr?.material_id}</h4>
                                        </div>
                                        <QRCode style={{ marginRight: '12px' }} value={currentQr?.material_id} bordered={false} size={100} type="svg" />
                                    </div>
                                </td>
                            </tr>
                            <tr style={{fontWeight: 600}}>
                                <td style={{paddingLeft: '2mm'}}>Số lượng</td>
                                <td style={{paddingLeft: '2mm'}}>{currentQr?.quantity}</td>
                            </tr>
                            <tr style={{fontWeight: 600}}>
                                <td style={{paddingLeft: '2mm'}}>Số máy</td>
                                <td style={{paddingLeft: '2mm'}}>{currentQr?.machine_number}</td>
                            </tr>
                            <tr style={{fontWeight: 600}}>
                                <td style={{paddingLeft: '2mm'}}>Tên công nhân</td>
                                <td style={{paddingLeft: '2mm'}}>{currentQr?.worker_name}</td>
                            </tr>
                            <tr style={{fontWeight: 600}}>
                                <td style={{paddingLeft: '2mm'}}>Ngày sản xuất</td>
                                <td style={{paddingLeft: '2mm'}}>{dayjs(currentQr?.manufacture_date).format('DD/MM/YYYY')}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/* <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 600, marginBottom: '10px' }}>{currentQr?.material_id}</span>
                        <span style={{ fontWeight: 600, marginBottom: '10px' }}>{currentQr?.machine_number}</span>
                        {currentQr?.manufacture_date ? (<span style={{ fontWeight: 600 }}>{dayjs(currentQr.manufacture_date).format('DD/MM/YYYY')}</span>) : (<></>)}
                    </div> */}
                </div>
            </div>
        )
    }

    const handlePrintQr = (record) => {
        setCurrentQr(record);
        setShowQrModal(true);
    }

    return <>
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical" form={formSearch} onFinish={() => btn_click()}>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="ID đơn" className='mb-3' name='id'>
                            <Input allowClear placeholder='Nhập ID' />
                        </Form.Item>
                        <Form.Item label="Mã hàng" className='mb-3' name='material_id'>
                            <Input allowClear placeholder='Nhập mã hàng' />
                        </Form.Item>
                        <button type='submit' hidden />
                    </Form>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Quản lý Template" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='file'
                            action={api.API_URL + "/template/import"}
                            headers={{
                                authorization: "Bearer " + JSON.parse(localStorage.getItem('authUser'))?.token,
                            }}
                            onChange={(info) => {
                                setLoadingExport(true);
                                if (info.file.status === 'error') {
                                    setLoadingExport(false);
                                    message.error(info.file?.response?.message ?? 'Upload file lỗi');
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        btn_click();
                                        message.success(info.file.response.message);
                                        setLoadingExport(false);
                                    } else {
                                        btn_click();
                                        message.error(info.file.response.message ?? 'Upload file lỗi');
                                        setLoadingExport(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        {/* <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button> */}
                        {/* <Button type="primary" onClick={insertRecord}>Insert</Button> */}
                        {/* <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + selectedRowKeys.length + " bản ghi đã chọn?"}
                            onConfirm={deleteMultiple}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={selectedRowKeys.length <= 0} danger>Delete</Button>
                        </Popconfirm> */}
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            pagination={{
                                current: page,
                                pageSize: pageSize,
                                total: total,
                                onChange: (page, pageSize) => {
                                    btn_click(page, pageSize)
                                }
                            }}
                            columns={columns}
                            dataSource={data}
                            scroll={{ x: '1250px' }}
                        // rowSelection={rowSelection}
                        />
                    </Spin>
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={onCancel} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={5}>
                    <Col span={12}>
                        <Form.Item name={'material_id'} className='mb-3' label={'Mã NVL'} rules={[{ required: true }]}>
                            <Select showSearch allowClear placeholder="NVL" options={customerOptions} optionFilterProp='label' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'quantity'} className='mb-3' label={'Số lượng'} rules={[{ required: true }]}>
                            <InputNumber placeholder="Số lượng" min={0} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>

        <Modal
            title={"Tem NVL"}
            open={showQrModal}
            onOk={() => {
                setShowQrModal(false);
            }}
            onCancel={() => {
                setShowQrModal(false);
            }}
            okText="OK"
            cancelText="Thoát"
            width={500}
            footer={
                <Space size="middle">
                    <ReactToPrint
                        trigger={() => <Button type="primary">In QR</Button>}
                        content={() => printRef.current}

                    />
                    <Button
                        type="default"
                        onClick={() => setShowQrModal(false)}>
                        Đóng
                    </Button>
                </Space>
            }
        >
            {htmlQr()}
        </Modal>
        <div style={{ display: 'none' }}>
            <div ref={printRef} style={{ backgroundColor: 'white' }}>
                {Array(currentQr?.roll_quantity ?? 1).fill().map((_, index) => (
                    <>
                        {htmlQr(index)}
                        <div style={{ pageBreakAfter: 'always' }}></div>
                    </>
                ))}
            </div>
        </div>
    </>
}

export default Template;
