import React, { useEffect, useRef, useState } from 'react';
import { CloseOutlined, DeleteOutlined, PrinterOutlined, QrcodeOutlined } from '@ant-design/icons';
import { Layout, Row, Col, Divider, Button, Table, Modal, Select, Steps, Input, Radio, Form, TreeSelect, InputNumber, Spin, message, Popconfirm } from 'antd';
import { withRouter, Link } from "react-router-dom";
import DataDetail from '../../../components/DataDetail';
import '../style.scss';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ScanButton from '../../../components/Button/ScanButton';
import SelectButton from '../../../components/Button/SelectButton';
import { getInfoPallet, getLine, getLineOverall, getLineUser, getTable, getTableChon, inTem, scanPallet, setAssignLineUser, setAssignTableUserWork } from '../../../api/oi/manufacture';
import Tem from '../../UI/Manufacture/Tem';
import { useReactToPrint } from 'react-to-print';
import dayjs from "dayjs";
import { getInfoChon } from '../../../api';
import { createAssignment, deleteAssignment, getAssignment, getLineList, getLotProductionList, getProductionOverall, printTemSelectionLine, scanForSelectionLine, updateAssignment } from '../../../api/phase2/oi/manufacture';
import DataDetail2 from '../../../components/DataDetail2';
import { render } from '@testing-library/react';

const columns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (value, record, index) => index + 1
    },
    {
        title: 'Lô sản xuất',
        dataIndex: 'lo_sx',
        key: 'lo_sx',
        align: 'center'
    },
    {
        title: 'Mã Pallet/cuộn',
        dataIndex: 'lot_id',
        key: 'lot_id',
        align: 'center'
    },
    {
        title: 'Tên sản phẩm',
        dataIndex: 'ten_sp',
        key: 'ten_sp',
        align: 'center'
    },
    {
        title: 'Mã hàng',
        dataIndex: 'ma_hang',
        key: 'ma_hang',
        align: 'center'
    },
    {
        title: 'TG bắt đầu (KH)',
        dataIndex: 'thoi_gian_bat_dau_kh',
        key: 'thoi_gian_bat_dau_kh',
        align: 'center',
    },
    {
        title: 'TG kết thúc (KH)',
        dataIndex: 'thoi_gian_ket_thuc_kh',
        key: 'thoi_gian_ket_thuc_kh',
        align: 'center',
    },
    {
        title: 'TG bắt đầu (TT)',
        dataIndex: 'thoi_gian_bat_dau',
        key: 'thoi_gian_bat_dau',
        align: 'center',
    },
    {
        title: 'TG kết thúc (TT)',
        dataIndex: 'thoi_gian_ket_thuc',
        key: 'thoi_gian_ket_thuc',
        align: 'center',
    },
    {
        title: 'SL đầu vào (KH)',
        dataIndex: 'sl_dau_vao_kh',
        key: 'sl_dau_vao_kh',
        align: 'center'
    },
    {
        title: 'SL đầu ra (KH)',
        dataIndex: 'sl_dau_ra_kh',
        key: 'sl_dau_ra_kh',
        align: 'center'
    },
    {
        title: 'SL đầu vào (TT)',
        dataIndex: 'sl_dau_vao',
        key: 'sl_dau_vao',
        align: 'center'
    },
    {
        title: 'SL đầu ra (TT)',
        dataIndex: 'sl_dau_ra',
        key: 'sl_dau_ra',
        align: 'center',
    },
    {
        title: 'SL đầu ra (TT OK)',
        dataIndex: 'sl_dau_ra_ok',
        key: 'sl_dau_ra_ok',
        align: 'center',
    },
    {
        title: 'SL tem vàng',
        dataIndex: 'sl_tem_vang',
        key: 'sl_tem_vang',
        align: 'center',
        className: 'yellow'
    },
    {
        title: 'Số lượng NG',
        dataIndex: 'sl_tem_ng',
        key: 'sl_tem_ng',
        align: 'center',
        className: 'red'
    },
    {
        title: 'Tỉ lệ HT',
        dataIndex: 'ti_le_ht',
        key: 'ti_le_ht',
        align: 'center',
    },
];
const defaultRow1 = [
    {
        title: 'SL KH ngày',
        key: 'tong_sl_trong_ngay_kh'
    },
    {
        title: 'SL T.Tế',
        key: 'tong_sl_thuc_te'
    },
    {
        title: 'SL Tem vàng',
        key: 'tong_sl_tem_vang',
        bg: '#f7ac27'
    },
    {
        title: 'SL NG',
        key: 'tong_sl_ng',
        bg: '#fb4b50'
    },
    {
        title: 'Tỷ lệ hoàn thành (%)',
        key: 'ty_le_hoan_thanh',
    },
]

const P2ManufactureSelection = (props) => {
    document.title = "Sản xuất";
    const { line } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [options, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [remain, setRemain] = useState(0);
    const [oldQuantity, setOldQuantity] = useState(0);
    const [formAssign] = Form.useForm();
    const [formPrint] = Form.useForm();
    const [listCheck, setListCheck] = useState([]);
    const [openPrint, setOpenPrint] = useState(false);
    const [quantityOK, setQuantityOK] = useState(0);
    const [quantityTon, setQuantityTon] = useState(0);
    const [selectedLot, setSelectedLot] = useState();
    const [dataOverall, setDataOverall] = useState();
    const [openAssign, setOpenAssign] = useState(false);
    const [openAssignTable, setOpenAssignTable] = useState(false);
    const componentRef1 = useRef();
    const fetchLine = async () => {
        const lineList = await getLineList({ type: 'sx' });
        setOption(lineList.data);
        // setMachineOption([]);
    }
    const fetchOverall = async () => {
        const lineOverall = await getProductionOverall({ line_id: line });
        setDataOverall(lineOverall.data);
    }
    const fetchLotDataTable = async () => {
        setLoading(true);
        const infoPallet = await getLotProductionList({ line_id: line });
        if (infoPallet.success) {
            setData((infoPallet.data ?? []).map((e, i) => {
                if (e.status == 1) {
                    setSpecifiedRowKey(i);
                }
                return { ...e, key: i };
            }));
            setSelectedLot(infoPallet.data.find(e => e.status === 1));
        } else {
            setSelectedLot();
        }
        setListCheck([]);
        setLoading(false);
    }
    const fetchWorkers = async () => {
        const workers = await getLineUser({ line_id: line });
        setUsers(workers.data.map(e => ({ ...e, label: e.name, value: e.id })));
    }
    const fetchAssignment = async () => {
        const assignment = await getAssignment({ lot_id: selectedLot?.lot_id });
        setAssignData(assignment.data);
        formConfirm.setFieldsValue(assignment.data[0] ?? null);
    }
    useEffect(() => {
        fetchLine();
        fetchWorkers();
    }, []);
    useEffect(() => {
        setSpecifiedRowKey(null);
        if (line) {
            fetchOverall();
            fetchLotDataTable();
        }
    }, [line])
    useEffect(() => {
        if (selectedLot && openAssign) {
            fetchAssignment();
        }
    }, [openAssign])
    const tableRef = useRef();
    const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
    const handleScrollToRow = () => {
        if (specifiedRowKey !== null && tableRef.current) {
            tableRef.current?.scrollTo({ key: specifiedRowKey });
        }
    };
    useEffect(() => {
        if (data.length > 0) {
            handleScrollToRow();
        }
    }, [specifiedRowKey]);
    useEffect(() => {
        if (listCheck.length > 0) {
            print();
        }
    }, [listCheck])
    const print = useReactToPrint({
        content: () => componentRef1.current
    });
    const onChangeLine = (value) => {
        history.push('/manufacture/' + value)
    }
    const onScan = async (result) => {
        var res = await scanForSelectionLine({ lot_id: result, line_id: line });
        if (res.success) {
            fetchLotDataTable();
        }
    }

    const onDeleteAssignment = async (record) => {
        var res = await deleteAssignment({ ...record });
        if (res.success) {
            fetchAssignment();
        }
    }
    const assignColumn = [
        {
            title: 'Công nhân',
            dataIndex: 'worker',
            key: 'worker_id',
            align: 'center',
            render: (value) => value?.name ?? "",
        },
        {
            title: 'Số lượng đầu vào giao việc',
            dataIndex: 'assigned_quantity',
            key: 'assigned_quantity',
            align: 'center',
        },
        {
            title: 'Xoá',
            dataIndex: 'delete_btn',
            key: 'delete_btn',
            align: 'center',
            render: (value, record) => <Popconfirm title="Bạn có chắc chắn muốn xoá?" onConfirm={() => onDeleteAssignment(record)}><DeleteOutlined style={{ color: 'red', fontSize: 16 }} /></Popconfirm>
        },
    ]

    const resultColumn = [
        {
            title: 'Công nhân',
            dataIndex: 'worker',
            key: 'worker',
            align: 'center',
            render: (value) => value.name,
        },
        {
            title: 'Số lượng đầu vào giao việc',
            dataIndex: 'assigned_quantity',
            key: 'assigned_quantity',
            align: 'center',
        },
        {
            title: 'Số lượng TT đầu vào chọn',
            dataIndex: 'actual_quantity',
            key: 'actual_quantity',
            align: 'center',
            render: (value, record, index) =>
                <Form.Item name={`actual_quantity`} noStyle>
                    <InputNumber inputMode='numeric' min={0} disabled={record.actual_quantity} />
                </Form.Item>
        },
        {
            title: 'Số lượng kiểm tra TT OK',
            dataIndex: 'ok_quantity',
            key: 'ok_quantity',
            align: 'center',
            render: (value, record, index) =>
                <Form.Item name={`ok_quantity`} noStyle>
                    <InputNumber inputMode='numeric' min={0} disabled={record.ok_quantity} />
                </Form.Item>
        },
    ]


    const [formConfirm] = Form.useForm();
    const [users, setUsers] = useState([]);
    const [assignData, setAssignData] = useState([])
    const onClickRow = (row) => {
        if (row.status !== 2) {
            setSelectedLot(row)
        }
    }
    useEffect(() => {
        counter.current = 0;
        if (!selectedLot) {
            setListCheck([])
        }
    }, [selectedLot])
    const onAssign = async (values) => {
        if (selectedLot) {
            var res = await createAssignment({ ...values, line_id: line, lot_id: selectedLot.lot_id });
            if (res.success) {
                fetchAssignment();
            }
            formAssign.resetFields();
        }
    }

    const rowClassName = (record, index) => {
        switch (record.status) {
            case 1:
                return 'table-row-green';
                break;
            case 2:
                return 'table-row-grey';
                break;
            default:
                return ""
                break;
        }
    }

    const counter = useRef(0);
    const handleOpenAssign = () => {
        if (selectedLot) {
            if(counter.current % 2 === 0){
                setOpenAssign(true);
            }else{
                setOpenAssignTable(true);
            }
            counter.current++;
            return;
        }
    }
    const defaultRow2 = [
        {
            title: 'Mã thùng',
            key: 'lot_id'
        },
        {
            title: 'Tên sản phẩm',
            key: 'ten_sp'
        },
        {
            title: 'UPH (Ấn định)',
            key: 'uph'
        },
        {
            title: 'UPH (Thực tế)',
            key: 'uph_tt'
        },
        {
            title: 'SL đầu ra (KH)',
            key: 'sl_dau_ra_kh'
        },
        {
            title: 'SL đầu ra (TT)',
            key: 'sl_dau_ra_hang_loat',
        },
        {
            title: 'SL đầu ra (TT OK)',
            key: 'sl_dau_ra_ok',
            onClick: () => {
                handleOpenAssign();
            }
        },
    ];
    useEffect(() => {
        formAssign.resetFields();
    }, [openAssign])
    

    const onFinishPrint = async (values) => {
        if (values.sl_in_tem > parseInt(assignData[0]?.ok_quantity) + parseInt(assignData[0]?.obb_quantity)) {
            message.error('Số lượng in tem phải nhỏ hơn số lượng OK + số lượng tồn');
        } else {
            // console.log(values);
            const res = await printTemSelectionLine({ lot_id: selectedLot.lot_id, line_id: line, sl_in_tem: values.sl_in_tem });
            if(res.success){
                setListCheck(res.data);
                setOpenPrint(false);
                fetchLotDataTable();
                formPrint.resetFields();
            }
        }
    }
    const openMdlPrint = async () => {
        if (selectedLot) {
            fetchAssignment();
            setOpenPrint(true);
        } else {
            message.info('Chưa chọn mã');
        }
    }

    const confirmResult = async (values) => {
        if(assignData.length < 0){
            message.error('Chưa có dữ liệu');
            return;
        }
        console.log({...assignData[0], ...values});
        var res = await updateAssignment(assignData[0]?.id, {...assignData[0], ...values});
        if(res.success){
            fetchAssignment();
            setOpenAssignTable(false);
        }
    }
    return (
        <React.Fragment>
            <Spin spinning={loading}>
                <Row className='mt-3' gutter={[8, 12]}>
                    <Col span={4}>
                        <SelectButton value={options.length > 0 && parseInt(line)} options={options} label="Chọn công đoạn" onChange={onChangeLine} />
                    </Col>
                    <Col span={20}>
                        <DataDetail2 data={defaultRow1} value={dataOverall} />
                    </Col>
                    <Col span={24}>
                        <ScanButton onScan={onScan} placeholder={"Quét mã QR hoặc nhập mã thùng"} />
                    </Col>
                    <Col span={20}>
                        <DataDetail2 data={defaultRow2} value={selectedLot} />
                    </Col>
                    <Col span={4}>
                        <Button size='large' type='primary' style={{ height: '100%', width: '100%', whiteSpace: "normal" }} onClick={openMdlPrint}>Nhập số lượng in tem</Button>
                        <div className="report-history-invoice">
                            <Tem listCheck={listCheck} ref={componentRef1} />
                        </div>
                    </Col>
                    <Col span={24}>
                        <Table
                            scroll={{
                                x: 200,
                                y: 350,
                            }}
                            size='small'
                            rowClassName={rowClassName}
                            pagination={false}
                            bordered
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => onClickRow(record)
                                };
                            }}
                            columns={columns}
                            dataSource={data} />
                    </Col>
                </Row>
                <Modal title="Giao việc" open={openAssign} width={600} onCancel={() => setOpenAssign(false)} onOk={() => { setOpenAssign(false); }}>
                    <Form
                        form={formAssign}
                        layout='horizontal'
                        onFinish={onAssign}
                        labelCol={{ span: 6 }}
                        labelAlign='left'
                        initialValues={{
                            table_id: '',
                            user_id: '',
                        }}>
                        <Form.Item name={"lot_id"} hidden>
                        </Form.Item>
                        <Form.Item name={"worker_id"} label={'Người phụ trách'}
                            rules={[{
                                required: true,
                                message: "Trường này là bắt buộc"
                            }]}>
                            <Select options={users} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button htmlType='submit' type='primary'>Thêm</Button>
                        </Form.Item>
                    </Form>
                    <h6><span className='float-left'>Số lượng còn: {remain}</span><span style={{ float: 'right' }}>Số lượng lẻ OK: {oldQuantity}</span></h6>
                    <Table
                        scroll={{
                            x: 200,
                            // y: 350,
                        }}
                        pagination={false}
                        bordered
                        columns={assignColumn}
                        dataSource={assignData}
                        size='small'
                    />
                </Modal>
                <Modal title={"Ghi nhận kết quả"} open={openAssignTable} width={800} onCancel={() => setOpenAssignTable(false)} onOk={() => { formConfirm.submit() }} cancelText="Đóng">
                    <Form form={formConfirm} onFinish={confirmResult}>
                        <Table
                            pagination={false}
                            bordered
                            columns={resultColumn}
                            dataSource={assignData}
                            size='small'
                        />
                    </Form>
                </Modal>
                <Modal title={"Nhập số lượng in tem"} open={openPrint} width={600} onCancel={() => setOpenPrint(false)} footer={null}>
                    <Divider className='mb-3 mt-1' />
                    <p>Số lượng OK: {assignData[0]?.so_luong}</p>
                    <p>Số lượng tồn: {assignData[0]?.so_luong}</p>
                    <Form layout="vertical" onFinish={onFinishPrint} form={formPrint}>
                        <Form.Item label="" name="sl_in_tem">
                            <InputNumber placeholder='Nhập số lượng in tem' style={{ width: '100%' }} />
                        </Form.Item>
                        <Button htmlType="submit" type="primary">In tem</Button>
                    </Form>
                </Modal>
            </Spin>
        </React.Fragment>
    );
};

export default P2ManufactureSelection;