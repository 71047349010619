import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm } from 'antd';
import { api, baseURL, token } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createProduct, deleteMultipleProduct, deleteProduct, exportProduct, getProduct, updateProduct } from '../../../api/ui/product';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import './MasterDataStyle.css'
import { getCustomers } from '../../../api/ui/main';

const Product = () => {
    document.title = "Quản lý sản phẩm";
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [total, setTotal] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [formSearch] = Form.useForm();
    const [editingRecord, setEditingRecord] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [customers, setCustomers] = useState([]);
    const columns = [
        {
            title: 'Mã sản phẩm',
            dataIndex: 'id',
            align: 'center',
            width: 100,
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'name',
            align: 'center',
            width: 180,
        },
        {
            title: 'Khách hàng',
            dataIndex: 'customer',
            align: 'center',
            render: (value, item, index) => value?.name,
            width: 370,
        },
        {
            title: 'Ver',
            dataIndex: 'ver',
            align: 'center',
            width: 50,
        },
        {
            title: 'His',
            dataIndex: 'his',
            align: 'center',
            width: 50,
        },
        {
            title: 'Khối lượng SP (gam/pcs)',
            dataIndex: 'weight',
            align: 'center',
            width: 100,
        },
        {
            title: 'Định mức giấy sử dụng (gam/pcs)',
            dataIndex: 'paper_norm',
            align: 'center',
            width: 100,
        },
        {
            title: 'Thao tác',
            dataIndex: 'action',
            align: 'center',
            width: 80,
            render: (_, record) => (
                <Space wrap>
                    <EditOutlined className='edit-btn' onClick={() => editRecord(record)} />
                    <Popconfirm
                        title="Xoá bản ghi"
                        description={"Bạn có chắc muốn xoá?"}
                        onConfirm={()=>deleteRecord(record)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <DeleteOutlined className='delete-btn' />
                    </Popconfirm>
                </Space>
            )
        },
    ];
    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadListTable({ ...formSearch.getFieldsValue(true), page, pageSize });
    }

    useEffect(() => {
        btn_click();
        fetchCustomer();
    }, [])

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setSelectedRowKeys([]);
        setLoading(true)
        const res = await getProduct(params);
        setData(res.data.data.map(e => {
            return { ...e, key: e.id }
        }));
        setTotal(res.data.total)
        setLoading(false);
    }

    const fetchCustomer = async () => {
        var res = await getCustomers();
        setCustomers(res.data.map(e=>({...e, value: e.id, label: e.name})));
    }

    const onFinish = async (values) => {
        console.log(values);
        if (isEdit) {
            const res = await updateProduct(editingRecord?.id, values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                setEditingRecord();
            }
        } else {
            const res = await createProduct(values);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
            }
        }
        btn_click();
    }

    const deleteRecord = async (record) => {
        const res = await deleteProduct(record.id);
        btn_click();
    }
    const deleteMultiple = async () => {
        console.log(selectedRowKeys);
        const res = await deleteMultipleProduct(selectedRowKeys);
        btn_click();
    }
    const editRecord = (record) => {
        setIsEdit(true);
        const fields = { ...record }
        form.setFieldsValue(fields);
        setEditingRecord(fields);
        setOpenMdl(true);
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportProduct({...formSearch.getFieldsValue(true)});
        if (res.success && res.data) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        selectedRowKeys: selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
        },
    };
    const onCancel = () => {
        form.resetFields();
        setOpenMdl(false);
    }
    return <>
        <Row style={{ padding: '8px', marginRight: 0 }} gutter={[8, 8]}>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical" form={formSearch} onFinish={() => btn_click()}>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Mã sản phẩm" className='mb-3' name='id'>
                            <Input allowClear placeholder='Nhập mã sản phẩm'/>
                        </Form.Item>
                        <Form.Item label="Tên sản phẩm" className='mb-3' name='name'>
                            <Input allowClear placeholder='Nhập tên sản phẩm'/>
                        </Form.Item>
                        <button type='submit' hidden />
                    </Form>
                </Card>
            </Col>
            <Col span={21}>
                <Card style={{ height: '100%' }} title="Quản lý sản phẩm" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='files'
                            action={api.API_URL + "/product/import"}
                            headers={{
                                authorization: "Bearer " + JSON.parse(localStorage.getItem('authUser'))?.token,
                            }}
                            onChange={(info) => {
                                setLoadingExport(true);
                                if (info.file.status === 'error') {
                                    setLoadingExport(false);
                                    message.error('Upload file lỗi');
                                } else if (info.file.status === 'done') {
                                    if (info.file.response.success === true) {
                                        btn_click();
                                        message.success(info.file.response.message);
                                        setLoadingExport(false);
                                    } else {
                                        btn_click();
                                        message.error(info.file.response.message ?? 'Upload file lỗi');
                                        setLoadingExport(false);
                                    }
                                }
                            }}
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + selectedRowKeys.length + " bản ghi đã chọn?"}
                            onConfirm={deleteMultiple}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={selectedRowKeys.length <= 0} danger>Delete</Button>
                        </Popconfirm>
                    </Space>
                }>
                    <Spin spinning={loading}>
                        <Table size='small' bordered
                            scroll={
                                {
                                    x: true,
                                    y: '80vh'
                                }
                            }
                            pagination={{
                                current: page,
                                pageSize: pageSize,
                                total: total,
                                onChange: (page, pageSize) => {
                                    btn_click(page, pageSize)
                                }
                            }}
                            columns={columns}
                            dataSource={data}
                            rowSelection={rowSelection} />
                    </Spin>
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={onCancel} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Form.Item name={'id'} className='mb-3' label={'Mã sản phẩm'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'name'} className='mb-3' label={'Tên sản phẩm'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'customer_id'} className='mb-3' label={'Tên khách hàng'} rules={[{ required: true }]}>
                            <Select options={customers} showSearch optionFilterProp='label'/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'ver'} className='mb-3' label={'Ver'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'his'} className='mb-3' label={'His'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'weight'} className='mb-3' label={'Khối lượng sản phẩm (gam/pcs)'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name={'paper_norm'} className='mb-3' label={'Định mức giấy sử dụng (gam/pcs)'} rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item className='mb-0 text-end'>
                    <Button type="primary" htmlType='submit'>Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default Product;
