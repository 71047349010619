import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Spin, Space, Modal, Radio } from 'antd';
import { Pie } from '@ant-design/charts';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useEffect } from "react";
import { getErrorsMachine, getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getThongSoMay } from '../../../api/ui/main';
import { baseURL } from '../../../config';
import { exportThongSoMay } from '../../../api/ui/export';
import dayjs from "dayjs";
import CalculateTableHeight from '../../../components/calculateTableHeight';

const { Sider } = Layout;
const { RangePicker } = DatePicker;


const Equipment2 = (props) => {
    document.title = 'UI - Thông số máy'
    const [listMachines, setListMachines] = useState([]);
    const [listStaffs, setListStaffs] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        machine_code: '',
        ca_sx: '',
        date: [dayjs(), dayjs()]
    });
    const col_detailTable = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
            align: 'center',
            width: 50,
            fixed: 'left'
        },
        {
            title: 'Ngày sản xuất',
            dataIndex: 'ngay_sx',
            key: 'ngay_sx',
            align: 'center',
            width: 120,
            fixed: 'left'
        },
        {
            title: 'Tên máy',
            dataIndex: 'machine_name',
            key: 'machine_name',
            align: 'center',
            render: (value, record, index) => record?.machine.name,
            width: 100,
            fixed: 'left'
        },
        {
            title: 'Ca SX',
            dataIndex: 'ca_sx',
            key: 'ca_sx',
            align: 'center',
            width: 60,
            render: (value) => parseInt(value) === 1 ? "Ca 1" : "Ca 2",
        },
    ];
    
    useEffect(() => {
        (async () => {
            const res4 = await getStaffs();
            setListStaffs(res4.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }))
            const res5 = await getMachineOfLine();
            setListMachines(res5.data.map(e => {
                return { ...e, label: e.name, value: e.code }
            }));
        })()
    }, []);

    const loadDataTable = async (params) => {
        setLoSX();
        setLoading(true)
        const res = await getThongSoMay(params);
        if (res.success) {
            console.log()
            setTotalPage(res.data.totalPage);
            setData(res.data.data.map(e => {
                let dataIf = e.data_if;
                Object.keys(dataIf ?? {}).forEach(function (key, index) {
                    dataIf[key] = { is_if: true, value: dataIf[key] };
                });
                let dataInput = e.data_input;
                Object.keys(dataInput ?? {}).forEach(function (key, index) {
                    dataInput[key] = { is_if: false, value: dataInput[key] };
                });
                return { ...e, ...dataIf, ...dataInput }
            }))
            setColumns([...col_detailTable, 
                ...Object.keys(res.data.columns ?? {}).map(key => {
                    return {
                        title: res.data.columns[key]?.title,
                        dataIndex: key,
                        key: key,
                        align: 'center',
                        width: 100,
                        render: (value, record) => {
                            return {
                                props: {
                                    style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                                },
                                children: value?.value ? !isNaN(value?.value) ? (Math.round(value?.value * 100) / 100) : value?.value : '-'
                            }
                        }
                    }
                })
            ])
        }
        setLoading(false);
    }

    async function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page, pageSize })
    }

    useEffect(() => {
        btn_click();
    }, [])
    const [loading, setLoading] = useState(false);

    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportThongSoMay({ ...params, lo_sx: loSX });
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const [openDetail, setOpenDetail] = useState(false);
    const detailColumns = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            render: (value, record, index) => index + 1,
            align: 'center'
        },
        {
            title: 'Lô sx',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center'
        },
        {
            title: 'Mã thùng/pallet',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center'
        },

        {
            title: 'Mã máy',
            dataIndex: 'machine_code',
            key: 'machine_code',
            align: 'center'
        },
        {
            title: 'In',
            align: 'center',
            children: [
                {
                    title: 'Tốc độ',
                    dataIndex: 'speed',
                    key: 'speed',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? Math.round(value?.value) : '-'
                        }
                    }
                },
                {
                    title: 'Độ ph',
                    dataIndex: 'ph',
                    key: 'ph',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Nhiệt độ nước',
                    dataIndex: 'w_temp',
                    key: 'w_temp',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Nhiệt độ môi trường',
                    dataIndex: 't_ev',
                    key: 't_ev',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Độ ẩm môi trường',
                    dataIndex: 'e_hum',
                    key: 'e_hum',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
            ]
        },
        {
            title: 'Phủ',
            align: 'center',
            children: [
                {
                    title: 'Công suất đèn UV1',
                    dataIndex: 'uv1',
                    key: 'uv1',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Công suất đèn UV2',
                    dataIndex: 'uv2',
                    key: 'uv2',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Công suất đèn UV3',
                    dataIndex: 'uv3',
                    key: 'uv3',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
            ]
        },
        {
            title: 'Bế',
            align: 'center',
            children: [
                {
                    title: 'Áp lực bế',
                    dataIndex: 'p_be',
                    key: 'p_be',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
            ]
        },
        {
            title: 'Gấp dán',
            align: 'center',
            children: [
                {
                    title: 'Áp lực băng tải 1',
                    dataIndex: 'p_conv1',
                    key: 'p_conv1',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Áp lực băng tải 2',
                    dataIndex: 'p_conv2',
                    key: 'p_conv2',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Áp lực súng bắn keo',
                    dataIndex: 'p_gun',
                    key: 'p_gun',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
                {
                    title: 'Nhiệt độ thùng keo',
                    dataIndex: 't_gun',
                    key: 't_gun',
                    align: 'center',
                    render: (value, record) => {
                        return {
                            props: {
                                style: { backgroundColor: value?.is_if ? '#ebebeb' : '' }
                            },
                            children: value?.value ? value?.value : '-'
                        }
                    }
                },
            ]
        },
    ]
    const [loSX, setLoSX] = useState();
    const onClickRow = async (record) => {
        setLoSX(record.lo_sx)
    }
    useEffect(() => {
        if (loSX) {
            (async () => {
                const res = await getThongSoMay({ ...params, lo_sx: loSX });
                if (res.success) {
                    setData(res.data.map(e => {
                        let dataIf = e.data_if;
                        Object.keys(dataIf ?? {}).forEach(function (key, index) {
                            dataIf[key] = { is_if: true, value: dataIf[key] };
                        });
                        let dataInput = e.data_input;
                        Object.keys(dataInput ?? {}).forEach(function (key, index) {
                            dataInput[key] = { is_if: false, value: dataInput[key] };
                        });
                        return { ...e, ...dataIf, ...dataInput }
                    }))
                }
            })()
        }
    }, [loSX]);
    const [dateType, setDateType] = useState('date');
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }
    return <>
        <Row style={{ padding: '8px'}} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Divider>Chọn máy</Divider>
                        <Form.Item className='mb-3'>
                            <Select
                                allowClear
                                placeholder="Nhập máy"
                                options={listMachines}
                                onChange={(value) => { setParams({ ...params, machine_code: value }); }}
                            />
                        </Form.Item>
                        <Divider>Thời gian truy vấn</Divider>
                        <Radio.Group
                            options={[
                                { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } }
                            ]}
                            className='d-flex justify-content-center mb-2 w-100'
                            onChange={(e) => setDateType(e.target.value)}
                            value={dateType}
                            optionType="button"
                            buttonStyle="solid"
                        />
                        <Space direction='vertical' style={{ width: '100%' }}>
                            <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                            <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                        </Space>
                        <Divider>Điều kiện truy vấn</Divider>
                        <Form.Item label="Ca" className='mb-3' name={"ca_sx"}>
                            <Select
                                showSearch
                                allowClear
                                placeholder="Chọn ca sản xuất"
                                options={
                                    [{
                                        label: 'Ca 1',
                                        value: 1,
                                    },
                                    {
                                        label: 'Ca 2',
                                        value: 2,
                                    },]
                                }
                                onChange={(value) => { setParams({ ...params, ca_sx: value }); }}
                            />
                        </Form.Item>
                        <Form.Item label="Thời gian ghi nhận IF" className='mb-3' name={"date_if"}>
                            <DatePicker placeholder='Chọn thời gian ghi nhận IF' style={{ width: '100%' }} onChange={(value) => { setParams({ ...params, date_if: value }); }} />
                        </Form.Item>
                        <Form.Item label="Thời gian ghi nhận nhập" className='mb-3' name={"date_input"}>
                            <DatePicker placeholder='Chọn thời gian ghi nhận nhập' style={{ width: '100%' }} onChange={(value) => { setParams({ ...params, date_input: value }); }} />
                        </Form.Item>

                        {/* <Form.Item label="Nhân viên" className='mb-3'>
                                <Select
                                    showSearch
                                    onChange={(value) => { setSelectedStaff(value) }}
                                    placeholder="Chọn nhân viên"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listStaffs}
                                />
                            </Form.Item> */}
                    </Form>
                </Card>
            </Col>
            <Col span={20} className='custom-col-table'>
                <Card style={{ height: '100%' }} title="Thông số máy" extra={<Button type='primary' onClick={exportFile} loading={exportLoading}>Xuất excel</Button>}>
                    <Table size='small' bordered
                        className="table-equipment2"
                        loading={loading}
                        pagination={{
                            current: page,
                            size: 'default',
                            total: totalPage,
                            onChange: (page, pageSize) => {
                                btn_click(page, pageSize)
                            }
                        }}
                        scroll={
                            {
                                y: CalculateTableHeight('table-equipment2')
                            }
                        }
                        // style={{height:'100%'}}
                        // onRow={(record, rowIndex) => {
                        //     return {
                        //         onClick: (event) => { onClickRow(record) },
                        //     };
                        // }}
                        columns={columns}
                        dataSource={data} />
                </Card>
            </Col>
            {/* <Modal title="Chi tiết thông số máy" open={openDetail} onCancel={() => setOpenDetail(false)}>
                <Table size='small' bordered
                    pagination={false}
                    scroll={
                        {
                            x: '150vw',
                            y: '60vh'
                        }
                    }
                    // style={{height:'100%'}}
                    columns={col_detailTable}
                    dataSource={data} />
            </Modal> */}
        </Row>

    </>
}

export default Equipment2;
