import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Popconfirm } from 'antd';
import { baseURL } from '../../../config';
import React, { useState, useRef, useEffect } from 'react';
import { createLine, deleteLine, exportLine, getLine, updateLine } from '../../../api';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { createStamps, deleteStamps, getStamps, importStamps, updateStamps } from '../../../api/phase2/master_data/stamp';
import { useReactToPrint } from 'react-to-print';
import Tem from '../Manufacture/Tem';

const Stamp = () => {
    document.title = "Quản lý tem";
    const [listCheck, setListCheck] = useState([]);
    const [openMdl, setOpenMdl] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [params, setParams] = useState({});
    const col_detailTable = [
        {
            title: 'Mã Pallet/thùng/cuộn',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
        },
        {
            title: 'Tên SP',
            dataIndex: 'ten_sp',
            key: 'ten_sp',
            align: 'center',
        },
        {
            title: 'Số lượng',
            dataIndex: 'soluongtp',
            key: 'soluongtp',
            align: 'center',
        },
        {
            title: 'Ver',
            dataIndex: 'ver',
            key: 'ver',
            align: 'center',
        },
        {
            title: 'His',
            dataIndex: 'his',
            key: 'his',
            align: 'center',
        },
        {
            title: 'Lô SX',
            dataIndex: 'lsx',
            key: 'lsx',
            align: 'center',
        },
        {
            title: 'Công đoạn thực hiện',
            dataIndex: 'cd_thuc_hien',
            key: 'cd_thuc_hien',
            align: 'center',
        },
        {
            title: 'Công đoạn tiếp theo',
            dataIndex: 'cd_tiep_theo',
            key: 'cd_tiep_theo',
            align: 'center',
        },
        {
            title: 'Người sản xuất',
            dataIndex: 'nguoi_sx',
            key: 'nguoi_sx',
            align: 'center',
        },
        {
            title: 'Ghi chú',
            dataIndex: 'ghi_chu',
            key: 'ghi_chu',
            align: 'center',
        }
    ]
    const formFields = [
        {
            key: 'id',
            hidden: true
        },
        {
            title: 'Tên',
            key: 'name',
            required: true
        },
        {
            title: 'Thứ tự',
            key: 'ordering',
        },
        {
            title: 'Hiển thị',
            key: 'display',
            isTrueFalse: true
        },
    ];

    const onSearch = (values) => {
        loadListTable(values);
    }

    const [data, setData] = useState([]);
    const loadListTable = async (params) => {
        setLoading(true)
        const res = await getStamps(params);
        setData(res.data.map(e => {
            return { ...e, key: e.id }
        }));
        setLoading(false);
    }
    useEffect(() => {
        (async () => {
            loadListTable(params);
        })()
    }, [])

    const onFinish = async (values) => {
        console.log(values);
        if (isEdit) {
            const res = await updateStamps(values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        } else {
            const res = await createStamps(values);
            console.log(res);
            if (res) {
                form.resetFields();
                setOpenMdl(false);
                loadListTable(params);
            }
        }
    }

    const deleteRecord = async () => {
        if (listCheck.length > 0) {
            const res = await deleteStamps(listCheck);
            setListCheck([]);
            loadListTable(params);
        } else {
            message.info('Chưa chọn bản ghi cần xóa')
        }
    }
    const editRecord = () => {
        setIsEdit(true)
        if (listCheck.length !== 1) {
            message.info('Chọn 1 bản ghi để chỉnh sửa');
        } else {
            const result = data.find((record) => record.id === listCheck[0]);
            form.setFieldsValue({ ...result, line: result?.line?.name });
            setOpenMdl(true);
        }
    }
    const insertRecord = () => {
        setIsEdit(false)
        form.resetFields();
        setOpenMdl(true);
    }
    const [loadingExport, setLoadingExport] = useState(false);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportLine(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const rowSelection = {
        // type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setListCheck(selectedRowKeys)
        },
    };
    const componentRef1 = useRef();
    const print = useReactToPrint({
        content: () => componentRef1.current
    });
    return <>
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={3} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => formSearch.submit()}>Truy vấn</Button>
                ]}>
                    <Divider>Tìm kiếm</Divider>
                    <Form
                        style={{ margin: '0 8px' }}
                        layout="vertical"
                        form={formSearch}
                        onFinish={onSearch}>
                        <Form.Item label="Mã pallet/cuộn" className='mb-3' name={'lot_id'}>
                            <Input allowClear onChange={(e) => setParams({ ...params, line: e.target.value })} placeholder='Nhập pallet/cuộn' />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={21} className='custom-col-table'>
                <Card style={{ height: '100%' }} title="Quản lý tem" extra={
                    <Space>
                        <Upload
                            showUploadList={false}
                            name='files'
                            customRequest={async ({ file, onSuccess, onError }) => {
                                const formData = new FormData();
                                formData.append('file', file);
                                const res = await importStamps(formData);
                                if (res.success) {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                } else {
                                    loadListTable(params);
                                    setLoadingExport(false);
                                }
                            }
                            }
                        >
                            <Button style={{ marginLeft: '15px' }} type="primary" loading={loadingExport}>
                                Upload Excel
                            </Button>
                        </Upload>
                        <Button type="primary" onClick={print}>In tem</Button>
                        <div className="report-history-invoice">
                            <Tem listCheck={data.filter(e=>listCheck.includes(e.key))} ref={componentRef1} />
                        </div>
                        {/* <Button type="primary" onClick={exportFile} loading={exportLoading}>Export Excel</Button>
                        <Button type="primary" onClick={editRecord} disabled={listCheck.length <= 0}>Edit</Button>
                        <Button type="primary" onClick={insertRecord}>Insert</Button>
                        <Popconfirm
                            title="Xoá bản ghi"
                            description={"Bạn có chắc xoá " + listCheck.length + " bản ghi đã chọn?"}
                            onConfirm={deleteRecord}
                            okText="Có"
                            cancelText="Không"
                            placement="bottomRight"
                        >
                            <Button type="primary" disabled={listCheck.length <= 0}>Delete</Button>
                        </Popconfirm> */}

                    </Space>
                }>
                    <Table size='small' bordered
                        loading={loading}
                        className='tem-table'
                        scroll={
                            {
                                x: '100%',
                                y: CalculateTableHeight('tem-table')
                            }
                        }
                        pagination={false}
                        columns={col_detailTable}
                        dataSource={data}
                        rowSelection={rowSelection} />
                </Card>
            </Col>
        </Row>
        <Modal title={isEdit ? 'Cập nhật' : 'Thêm mới'} open={openMdl} onCancel={() => setOpenMdl(false)} footer={null} width={800}>
            <Form style={{ margin: '0 15px' }}
                layout="vertical"
                form={form}
                onFinish={onFinish}>
                <Row gutter={[16, 16]}>
                    {formFields.map(e => {
                        if (e.key !== 'select' && e.key !== 'stt') return <Col span={!e.hidden ? 12 : 0}>
                            <Form.Item name={e.key} className='mb-3' label={e.title} hidden={e.hidden} rules={[{ required: e.required }]}>
                                {!e.isTrueFalse ?
                                    <Input disabled={e.disabled || (isEdit && e.key === 'id')}></Input>
                                    :
                                    <Select>
                                        <Select.Option value={1}>Có</Select.Option>
                                        <Select.Option value={0}>Không</Select.Option>
                                    </Select>
                                }
                            </Form.Item>
                        </Col>
                    })}
                </Row>
                <Form.Item className='mb-0'>
                    <Button type="primary" htmlType='submit' >Lưu lại</Button>
                </Form.Item>
            </Form>
        </Modal>
    </>
}

export default Stamp;
