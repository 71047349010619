import { DeleteOutlined, EditOutlined, UploadOutlined, PlusOutlined, FileExcelOutlined, PrinterOutlined, LoadingOutlined } from '@ant-design/icons';
import { DatePicker, Col, Row, Card, Table, Tag, Layout, Divider, Button, Form, Input, theme, Select, AutoComplete, Upload, message, Checkbox, Space, Modal, Spin, Tree, Radio, InputNumber, Empty, Popconfirm } from 'antd';
import { Pie } from '@ant-design/charts';
import { baseURL } from '../../../config';
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import React, { useState, useRef, useEffect } from 'react';
import { getLines, getMachineOfLine, getCustomers, getProducts, getStaffs, getLoSanXuat, getWarehouses, getCaSanXuats, getDataFilterUI, getTreeLines } from '../../../api/ui/main';
import { deleteRecordProductPlan, exportKHSX, getInfoCongDoan, getListProductPlan, searchInfoCongDoan, storeProductPlan, updateProductPlan } from '../../../api';
import dayjs from "dayjs";
import { getTreeSelect } from '../../../api/phase2/ui/main';
import CalculateTableHeight from '../../../components/calculateTableHeight';
import { getProductOrders, updateNumberMachine } from '../../../api/phase2/master_data/product-order';
import { createAutoProductionPlan, generateProductionPlan } from '../../../api/phase2/ui/production';
import { getLineList } from '../../../api/phase2/oi/manufacture';
import { manufatureQuicker } from '../../../routes/quickerCollection';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
	arrayMove,
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
const { Sider } = Layout;
const { RangePicker } = DatePicker;
const CustomRow = (props) => {
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: props['data-row-key'],
	});
	const style = {
		...props.style,
		transform: CSS.Translate.toString(transform),
		transition,
		cursor: 'move',
		...(isDragging
			? {
				position: 'relative',
				zIndex: 9999,
			}
			: {}),
	};
	return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};
const TaoKeHoachSanXuat = () => {
	const [listLines, setListLines] = useState([]);
	const [listNameProducts, setListNameProducts] = useState([]);
	const [listLoSX, setListLoSX] = useState([]);
	const [listCustomers, setListCustomers] = useState([]);
	const [totalPage, setTotalPage] = useState(1);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(20);
	const [listCheck, setListCheck] = useState([]);
	const [openMdlEdit, setOpenMdlEdit] = useState(false);
	const [titleMdlEdit, setTitleMdlEdit] = useState('Cập nhật');
	const [form] = Form.useForm();
	const [params, setParams] = useState({ date: [dayjs(), dayjs()] });
	const [dateType, setDateType] = useState('date');
	const tableH = useRef('72vh');
	const [lineManufacture, setLineManufacture] = useState([]);
	const columns = [
		{ title: 'Thứ tự ưu tiên', dataIndex: 'priority', key: 'priority', render: (value, record, index) => index + 1, align: 'center', width: 50, fixed: 'left' },
		{ title: 'Mã đơn', dataIndex: 'id', align: 'center', width: 120, fixed: 'left' },
		{ title: 'Tên sản phẩm', dataIndex: 'product_name', align: 'center', width: 100, render: (_, record) => record?.product?.name },
		{ title: 'Khách hàng', dataIndex: 'khach_hang', align: 'center', width: 100, render: (_, record) => record?.customer?.name },
		{ title: 'Ngày giao hàng', dataIndex: 'delivery_date', align: 'center', width: 110 },
		{ title: 'Kho NVL', dataIndex: 'product_name', align: 'center', width: 180 },
		{
			title: 'Tồn công đoạn', dataIndex: 'ton', children: lineManufacture.map(e => ({
				title: e.label,
				dataIndex: 'ton' + e.value,
				align: 'center',
				render: (_, record) => (record?.ton ?? []).find(o => e.value == o.line_id)?.value
			}))
		},
		{
			title: 'Số lượng máy', dataIndex: 'so_luong_may', children: lineManufacture.map(e => ({
				title: e.label,
				dataIndex: 'sl_may' + e.value,
				align: 'center',
				render: (_, record) => (record?.sl_may ?? []).find(o => e.value == o.line_id)?.value
			}))
		},
		{ title: 'Số lượng đơn hàng', dataIndex: 'quantity', align: 'center', width: 160 },
		{ title: 'Số lượng FC', dataIndex: 'fc_quantity', align: 'center', width: 160 },
		{ title: 'Số lượng tồn kho', dataIndex: 'sl_ton', align: 'center', width: 120 },
		{ title: 'Số lượng giao', dataIndex: 'sl_giao_sx', align: 'center', width: 100 },
		{ title: 'SL còn chưa sx', dataIndex: 'sl_con', align: 'center', width: 100 },
		{
			title: 'Tác vụ',
			dataIndex: 'action',
			key: 'action',
			align: 'center',
			fixed: 'right',
			render: (_, record) => (
				<Space wrap>
					<EditOutlined onClick={() => editRecord(record)} style={{ fontSize: 18, color: 'blue', cursor: 'pointer' }} />
				</Space>
			)
		}
	];

	useEffect(() => {
		(async () => {
			const res1 = await getTreeSelect();
			setListLines(res1.data);
			const res5 = await getCustomers();
			setListCustomers(res5.data.map(e => {
				return { ...e, label: e.name, value: e.id }
			}));
			const line = await getLineList({ type: 'sx' });
			setLineManufacture(line.data ?? []);
		})()
	}, [])

	function btn_click(page = 1, pageSize = 20) {
		setPage(page);
		setPageSize(pageSize);
		loadListTable(params);
	}

	useEffect(() => {
		(async () => {
			var res = await getDataFilterUI({ khach_hang: params.khach_hang });
			if (res.success) {
				setListNameProducts(res.data.product.map(e => {
					return { ...e, label: e.name, value: e.id }
				}));
				setListLoSX(Object.values(res.data.lo_sx).map(e => {
					return { label: e, value: e }
				}));
			}
		})()
	}, [params.khach_hang])

	const [data, setData] = useState([]);
	const loadListTable = async (params) => {
		setLoading(true)
		// const res = await getListProductPlan(params);
		if (params?.date) {
			if (params.date[0]) params.start_date = dayjs(params.date[0]).format('YYYY-MM-DD');
			if (params.date[1]) params.end_date = dayjs(params.date[1]).format('YYYY-MM-DD');
		}
		const res = await getProductOrders(params);
		setData((res.data?.data ?? []).map(e => {
			return { ...e, key: e.id }
		}));
		setLoading(false)
	}
	useEffect(() => {
		(async () => {
			loadListTable(params);
		})()
	}, [])

	useEffect(() => {
		tableH.current = CalculateTableHeight('table-tao-khsx') - 50;
	}, [data]);

	const [messageApi, contextHolder] = message.useMessage();

	const onFinish = async (values) => {
		console.log(values);
		var res = await updateNumberMachine(values);
		if (res.success) {
			setOpenMdlEdit(false);
			form.resetFields();
			loadListTable(params);
		}
	}
	const editRecord = (record) => {
		console.log(record);

		setTitleMdlEdit('Cập nhật')
		form.setFieldsValue(record)
		setOpenMdlEdit(true);
	}
	const [loading, setLoading] = useState(false);
	const onCheck = (checkedKeys, info) => {
		const selectKeys = [];
		if (info.node.type === 'factory') {
			if (info.checked) {
				info.node.children.map(e => {
					selectKeys.push(e.key?.toString())
				})
			}
		} else {
			info.checkedNodes.map(e => {
				switch (e.type) {
					case 'line':
						selectKeys.push(e.key?.toString());
						break;
					case 'machine':
						selectKeys.push(e.line_id?.toString());
						break;
					default:
						break;
				}
			})
		}
		setParams({ ...params, line_id: [...new Set(selectKeys)] })
	};
	const customDateFormat = (value) => {
		switch (dateType) {
			case 'week':
				return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
				break;
			case 'month':
				return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
				break;
			case 'year':
				return (`Năm ${dayjs(value).format('YYYY')}`);
				break;
			default:
				return (dayjs(value).format('DD/MM/YYYY'));
				break;
		}
	}
	useEffect(() => {
		onChangeDate('start_date', params.date[0], dateType);
		onChangeDate('end_date', params.date[1], dateType);
	}, [dateType])
	const onChangeDate = (position, value, dateType) => {
		switch (dateType) {
			case 'week':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
				}
				break;
			case 'month':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
				}
				break;
			case 'year':
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
				}
				break;
			default:
				if (position === 'start_date') {
					setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
				} else {
					setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
				}
				break;
		}
	}
	const renderQuicker = () => {
		const className = (path) => {
			if (window.location.pathname.indexOf(path) > -1) {
				return 'active';
			} else {
				return 'inactive';
			}
		}
		return (
			<ul>
				{manufatureQuicker.map(e => {
					return (
						<li><Link to={e.path} className={'quicker ' + className(e.path)}>{e.name}</Link></li>
					)
				})}
			</ul>
		)
	}

	const rowSelection = {
		// type: 'radio',
		selectedKeys: listCheck,
		onChange: (selectedRowKeys, selectedRows) => {
			setListCheck(selectedRowKeys);
		}
	}
	const [openOpenModal, setOpenModal] = useState(false);
	const [previewData, setPreviewData] = useState({
		plans: [],
		lots: [],
		machines: [],
		lo_sx: [],
	});
	const [loadingPreview, setLoadingPreview] = useState(false);
	const previewProductionPlan = async () => {
		if (listCheck.length <= 0) {
			messageApi.error('Chưa chọn đơn hàng');
			return;
		}
		setLoadingPreview(true);
		const sortedKeys = listCheck.sort(
			(a, b) => data.findIndex(item => item.key === a) - data.findIndex(item => item.key === b)
		);
		var res = await generateProductionPlan({ order_id: sortedKeys });
		if (res.success) {
			const plans = [];
			const machines = [];
			const lots = [];
			const lo_sx = [];
			(res.data ?? []).forEach(result => {
				(result?.plans ?? []).forEach(element => {
					plans.push(element);
				});
				(result?.lots ?? []).forEach(element => {
					lots.push(element);
				});
				(result?.machines ?? []).forEach(element => {
					machines.push(element);
				});
				if (result.lo_sx) {
					lo_sx.push(result.lo_sx);
				}
			});
			setPreviewData({ plans, lots, machines, lo_sx });
			setOpenModal(true);
		}
		setLoadingPreview(false);
	}

	const previewProductionPlanColumns = [
		{
			title: 'STT',
			dataIndex: 'stt',
			key: 'stt',
			align: 'center',
			fixed: 'left',
			width: '40px',
			render: (_, item, index) => index + 1
		},
		{
			title: 'Mã đơn hàng',
			dataIndex: 'product_order_id',
			key: 'product_order_id',
			align: 'center',
			fixed: 'left',
			width: '100px'
		},
		{
			title: 'Lô SX',
			dataIndex: 'lo_sx',
			key: 'lo_sx',
			align: 'center',
			fixed: 'left',
			width: '100px',
		},
		{
			title: 'Số lượng sx',
			dataIndex: 'sl_giao_sx',
			key: 'sl_giao_sx',
			align: 'center',
			fixed: 'left',
			width: '100px',
		},
		{
			title: 'Thời gian bắt đầu',
			dataIndex: 'thoi_gian_bat_dau',
			key: 'thoi_gian_bat_dau',
			align: 'center',
			width: '150px',
			fixed: 'left',
			render: (value) => (value && dayjs(value)?.isValid()) ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : null
		},
		{
			title: 'Thời gian kết thúc',
			dataIndex: 'thoi_gian_ket_thuc',
			key: 'thoi_gian_ket_thuc',
			align: 'center',
			width: '150px',
			fixed: 'left',
			render: (value) => (value && dayjs(value)?.isValid()) ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : null
		},
		{
			title: 'Ca sx',
			dataIndex: 'ca_sx',
			key: 'ca_sx',
			align: 'center',
			width: '50px',
		},
		{
			title: 'Công đoạn',
			dataIndex: 'cong_doan_sx',
			key: 'cong_doan_sx',
			align: 'center',
			width: '150px',
		},
		{
			title: 'Máy sx',
			dataIndex: 'machine_id',
			key: 'machine_id',
			align: 'center',
			width: '100px',
		},
		{
			title: 'Mã SP',
			dataIndex: 'product_id',
			key: 'product_id',
			align: 'center',
			width: '100px',
		},
		{
			title: 'Tên SP',
			dataIndex: 'ten_san_pham',
			key: 'ten_san_pham',
			align: 'center',
			width: '180px',
		},
		{
			title: 'KH',
			dataIndex: 'khach_hang',
			key: 'khach_hang',
			align: 'center',
			width: '250px',
		},
		{
			title: 'Ngày giao hàng',
			dataIndex: 'delivery_date',
			key: 'delivery_date',
			align: 'center',
			width: '100px',
		},
	];
	const onCancelPreview = () => {
		setOpenModal(false);
	}
	const onRunPreview = async () => {
		var res = await createAutoProductionPlan(previewData);
		if (res.success) {
			setPreviewData({ plans: [], lots: [], machines: [] });
			setOpenModal(false);
		}
	}

	const previewClassName = (record) => {
		if (record.is_exceed_time) {
			return 'red-row';
		}
		return "";
	}

	const onValuesChange = (changedValues, allValues) => {
		if ('fc_quantity' in changedValues) {
			form.setFieldValue('sl_giao_sx', (allValues?.quantity ?? 0) - (allValues?.fc_quantity ?? 0))
		}
	}

	const orderPreviewColumns = [
		{
			title: 'STT',
			dataIndex: 'stt',
			key: 'stt',
			align: 'center',
			// fixed: 'left',
			width: '40px',
			render: (_, item, index) => index + 1
		},
		{
			title: 'Mã đơn hàng',
			dataIndex: 'product_order_id',
			key: 'product_order_id',
			align: 'center',
			// fixed: 'left',
			width: '100px'
		},
		{
			title: 'Lô SX',
			dataIndex: 'lo_sx',
			key: 'lo_sx',
			align: 'center',
			// fixed: 'left',
			width: '100px',
		},
		{
			title: 'Số lượng sx',
			dataIndex: 'sl_giao_sx',
			key: 'sl_giao_sx',
			align: 'center',
			// fixed: 'left',
			width: '100px',
		},
		{
			title: 'Thời gian bắt đầu',
			dataIndex: 'thoi_gian_bat_dau',
			key: 'thoi_gian_bat_dau',
			align: 'center',
			width: '150px',
			// fixed: 'left',
			render: (value) => (value && dayjs(value)?.isValid()) ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : null
		},
		{
			title: 'Thời gian kết thúc',
			dataIndex: 'thoi_gian_ket_thuc',
			key: 'thoi_gian_ket_thuc',
			align: 'center',
			width: '150px',
			// fixed: 'left',
			render: (value) => (value && dayjs(value)?.isValid()) ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : null
		},
		{
			title: 'Mã SP',
			dataIndex: 'product_id',
			key: 'product_id',
			align: 'center',
			width: '100px',
		},
		{
			title: 'Tên SP',
			dataIndex: 'product_name',
			key: 'product_name',
			align: 'center',
			width: '180px',
		},
		{
			title: 'KH',
			dataIndex: 'khach_hang',
			key: 'khach_hang',
			align: 'center',
			width: '250px',
		},
		{
			title: 'Ngày giao hàng',
			dataIndex: 'delivery_date',
			key: 'delivery_date',
			align: 'center',
			width: '100px',
		},
	];
	const expandedRowRender = (columns, data) => {
		return <Table
			style={{ margin: 4 }}
			bordered
			columns={columns}
			dataSource={data}
			pagination={false}
			size='small'
			loading={loading}
			scroll={{ x: 2000 }}
			rowClassName={previewClassName} />;
	};
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				// https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
				distance: 1,
			},
		}),
	);
	const onDragEnd = ({ active, over }) => {
		if (active.id !== over?.id) {
			setData((prev) => {
				const activeIndex = prev.findIndex((i) => i.key === active.id);
				const overIndex = prev.findIndex((i) => i.key === over?.id);
				return arrayMove(prev, activeIndex, overIndex);
			});
		}
	};
	return <>
		{contextHolder}
		<Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
			<Col span={4} className='custom-col'>
				<Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
					<Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
				]}>
					<Form style={{ margin: '0 8px' }} layout="vertical">
						<Divider>Công đoạn</Divider>
						<Form.Item className='mb-3'>
							{
								listLines.length ?
									<Tree
										defaultExpandedKeys={[2]}
										style={{ maxHeight: 250, overflowY: 'auto' }}
										checkable
										selectable={false}
										onCheck={onCheck}
										treeData={listLines}
									/>
									:
									<LoadingOutlined />
							}
						</Form.Item>
						<Divider>Quicker</Divider>
						{renderQuicker()}
						<Divider>Thời gian truy vấn</Divider>
						<Radio.Group
							options={[
								{ value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
								{ value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
							]}
							size='small'
							className='d-flex justify-content-center mb-2 w-100'
							onChange={(e) => setDateType(e.target.value)}
							value={dateType}
							optionType="button"
							buttonStyle="solid"
						/>
						<Space direction='vertical' style={{ width: '100%' }}>
							<DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
							<DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
						</Space>
						<Divider>Điều kiện truy vấn</Divider>
						<Form.Item label="Khách hàng" className='mb-3'>
							<Select
								allowClear
								showSearch
								placeholder="Chọn khách hàng"
								onChange={(value) => setParams({ ...params, khach_hang: value })}
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
								options={listCustomers}
							/>
						</Form.Item>
						<Form.Item label="Tên sản phẩm" className='mb-3'>
							<Select
								allowClear
								showSearch
								onChange={(value) => {
									setParams({ ...params, ten_sp: value })
								}}
								placeholder="Nhập tên sản phẩm"
								optionFilterProp="children"
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={listNameProducts}
							/>
						</Form.Item>
					</Form>
				</Card>
			</Col>
			<Col span={20}>
				<Card style={{ height: '100%' }} styles={{ body: { paddingBottom: 0 } }} title="Tạo KHSX" extra={
					<Space>
						{/* <Button type="primary" onClick={editRecord}>Edit</Button> */}
						{/* <Button type="primary" onClick={insertRecord}>Insert</Button> */}
						<Button type="primary" loading={loadingPreview} onClick={previewProductionPlan}>Preview</Button>
					</Space>
				}>
					<DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
						<SortableContext
							// rowKey array
							items={data.map((i) => i.key)}
							strategy={verticalListSortingStrategy}
						>
							<Table size='small' bordered
								locale={{ emptyText: '' }}
								className="table-tao-khsx"
								loading={loading}
								components={{
									body: {
										row: CustomRow,
									},
								}}
								pagination={false}
								// pagination={{
								// 	current: page,
								// 	pageSize: pageSize,
								// 	size: "default",
								// 	total: totalPage,
								// 	onChange: (page, pageSize) => {
								// 		btn_click(page, pageSize);
								// 	}
								// }}
								scroll={
									{
										x: 2200,
										y: 'calc(100vh - 260px)',
									}
								}
								rowSelection={rowSelection}
								columns={columns}
								dataSource={data} />
						</SortableContext>
					</DndContext>
				</Card>
			</Col>
		</Row>
		<Modal title={titleMdlEdit} open={openMdlEdit} onCancel={() => setOpenMdlEdit(false)} footer={null}>
			<Form style={{ margin: '0 15px' }}
				layout="vertical"
				form={form}
				onFinish={onFinish}
				onValuesChange={onValuesChange}
				initialValues={{
					// sl_may: [
					// 	{ name: 'Gấp dán', value: 2, id: 24 },
					// 	{ name: 'In', value: 2, id: 25 },
					// 	{ name: 'Đục cắt', value: 2, id: 26 },
					// 	{ name: 'Liner', value: 2, id: 27 },
					// 	{ name: 'Chọn', value: 2, id: 29 },
					// ]
				}}>
				<Form.Item name={"id"} hidden></Form.Item>
				<Form.Item label={"Số lượng máy"} style={{ marginBottom: 0 }}>
					<Form.List name="sl_may">
						{(fields, { add, remove }, { errors }) => {
							return fields.map((field, index) => (
								<Form.Item
									shouldUpdate
									label={form.getFieldValue(['sl_may', field.key, 'name'])}
									key={field.key}
									style={{ marginLeft: 24 }}
								>
									<Form.Item
										{...field}
										name={[field.key, 'value']}
										noStyle
									>
										<InputNumber placeholder="Số lượng máy" style={{ width: '100%' }} min={0} />
									</Form.Item>
								</Form.Item>
							))
						}}
					</Form.List>
				</Form.Item>
				{/* </Col>
					<Col span={12}> */}
				<Form.Item label="Số lượng ĐH" name="quantity" hidden></Form.Item>
				<Form.Item label="Số lượng FC" name="fc_quantity" style={{ marginBottom: 12 }} rules={[{ required: true }]}>
					<InputNumber placeholder='Số lượng FC' style={{ width: '100%' }} min={0}></InputNumber>
				</Form.Item>
				<Form.Item label="Số lượng giao" name="sl_giao_sx" style={{ marginBottom: 12 }} rules={[{ required: true }]} shouldUpdate>
					<InputNumber placeholder='Số lượng giao' style={{ width: '100%' }} min={0}></InputNumber>
				</Form.Item>
				{/* </Col> */}
				{/* </Row> */}
				<Form.Item className='mb-0'>
					<Button type="primary" htmlType='submit' >Lưu lại</Button>
				</Form.Item>
			</Form>
		</Modal>
		<Modal open={openOpenModal} title="Xem trước" width={2000} onCancel={onCancelPreview}
			footer={
				<Space>
					<Button onClick={onCancelPreview}>Huỷ</Button>
					<Popconfirm
						title="Xác nhận tạo KH"
						description="Bạn có chắc muốn tạo KH?"
						onConfirm={onRunPreview}
						// onCancel={onCancelPreview}
						okText="Có"
						cancelText="Không"
					>
						<Button type='primary'>Run</Button>
					</Popconfirm>
				</Space>
			}>
			<Table size='small' bordered
				locale={{ emptyText: <Empty style={{ height: '100vh' }} image={null} description={null} /> }}
				className="table-khsx"
				loading={loading}
				pagination={false}
				scroll={{
					x: true
				}}
				expandable={{
					expandedRowRender: (record) => expandedRowRender(previewProductionPlanColumns, record.plans),
				}}
				rowClassName={previewClassName}
				columns={orderPreviewColumns}
				dataSource={(previewData.lo_sx ?? []).map((e, i) => ({ ...e, key: i }))} />
		</Modal>
	</>
}

export default TaoKeHoachSanXuat;
