import React, { useState, useEffect } from 'react';
import {
    DeleteOutlined,
    EditOutlined,
    UploadOutlined,
    PlusOutlined,
    FileExcelOutlined,
    PrinterOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import {
    DatePicker,
    Col,
    Row,
    Card,
    Table,
    Tag,
    Layout,
    Divider,
    Button,
    Form,
    Select,
    AutoComplete,
    Progress,
    Space,
    Tree,
    Spin,
    Radio
} from 'antd';
import "../style.scss";
import { Pie, Column, Line, G2 } from '@ant-design/charts';
import {
    getLines,
    getKpi,
    getProducts,
    getCustomers,
    getAllMachine,
    exportKPI
} from '../../../api/ui/main';
import dayjs from 'dayjs';
import config, { baseURL } from '../../../config';
import ProductivityChart from './ProductivityChart';
import MachinePerformanceChart from './MachinePerformanceChart';
import MachineStopChart from './MachineStopChart';
import PickWastagesChart from './PickWastagesChart';
import WastagesChart from './WastagesChart';
import PassRateChart from './PassRateChart';
import NotGoodRateChart from './NotGoodRateChart';
import QualityLineChart from './QualityLineChart';
import { getReportPPTX } from '../../../api/phase2/dashboard/main';

const KPI = (props) => {
    document.title = 'UI - KPI';
    const [listLines, setListLines] = useState([]);
    const [form] = Form.useForm();
    const dateType = Form.useWatch('dateType', form);

    const [params, setParams] = useState({
        dateType: 'date',
        start_date: dayjs(),
        end_date: dayjs()
    });

    const [dataTable, setDataTable] = useState([]);
    const [columnsDate, setColumnsDate] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const count = params.end_date.diff(params.start_date, 'day');
        const colDate = [];
        if (count > 0) {
            for (let i = 0; i <= count; i++) {
                colDate.push({
                    title: params.start_date.add(i, 'day').format('DD-MM-YYYY'),
                    dataIndex: params.start_date.add(i, 'day').format('YYYY-MM-DD'),
                    key: params.start_date.add(i, 'day').format('YYYY-MM-DD'),
                    align: 'center'
                })
            }
        }
        setColumnsDate(colDate);
    }, [dataTable])


    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        setTreeData(listLines.map(e => {
            let child = e.machine?.map(i => {
                return { title: i.name, key: i.id }
            })
            return {
                ...e,
                title: e.name,
                key: e.id,
                children: child
            }
        }));
    }, [listLines])

    async function btn_click() {
        setParams(form.getFieldsValue());
    }

    useEffect(()=>{
        console.log(params);
    }, [params])

    const [exportLoading, setExportLoading] = useState(false);
    const exportClick = async () => {
        setExportLoading(true);
        const res = await exportKPI(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    }
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(()=>{
        onChangeDate('start_date', params.start_date, dateType);
        onChangeDate('end_date', params.end_date, dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('isoWeek'), end_date: dayjs(params.end_date).endOf('isoWeek') });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('month'), end_date: dayjs(params.end_date).endOf('month') });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') });
                } else {
                    setParams({ ...params, start_date: dayjs(value).startOf('year'), end_date: dayjs(params.end_date).endOf('year') });
                }
                break;
            default:
                setParams({ ...params, [position]: value })
                break;
        }
    }

    const handleReport = async () => {
        setLoading(true);
        const response = await getReportPPTX()
        if (response?.file) {
            window.location = `${config.baseURL}/${response.file}`;
        }
        setLoading(false);
    }
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 15px' }} layout="vertical" form={form} onFinish={() => btn_click()} initialValues={params}>
                            <Divider>Thời gian truy vấn</Divider>
                            <Form.Item label='Chọn thời gian' name='dateType' noStyle>
                                <Radio.Group
                                    options={[
                                        { value: 'date', label: 'Ngày', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                        { value: 'week', label: 'Tuần', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                        { value: 'month', label: 'Tháng', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                        { value: 'year', label: 'Năm', style: { width: '33%', justifyContent: 'center', display: 'flex' } },
                                    ]}
                                    size='small'
                                    className='d-flex justify-content-center mb-2 w-100'
                                    optionType="button"
                                    buttonStyle="solid"
                                />
                            </Form.Item>

                            <Space direction='vertical' style={{ width: '100%' }}>
                                <Form.Item label='Chọn ngày' name='start_date' noStyle>
                                    <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} picker={dateType} format={customDateFormat}/>
                                </Form.Item>
                                <Form.Item label='Chọn ngày' name='end_date' noStyle>
                                    <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} picker={dateType} format={customDateFormat}/>
                                </Form.Item>
                            </Space>
                        </Form>
                    </Card>
                </Col>
                <Col span={20} className='custom-col'>
                    <Spin spinning={loading}>
                        <Row gutter={
                            [8, 8]
                        }>
                            <Col span={24}>
                                <div style={{textAlign: 'right', padding: '10px', backgroundColor: 'white'}}>
                                    <Button type="primary" onClick={handleReport}>Xuất file báo cáo</Button>
                                </div>
                            </Col>
                            <Col span={12}>
                                <ProductivityChart params={params}/>
                            </Col>
                            <Col span={12}>
                                <MachinePerformanceChart />
                            </Col>
                            <Col span={12}>
                                <MachineStopChart />
                            </Col>
                            {/* <Col span={12}>
                                <PickWastagesChart />
                            </Col>
                            <Col span={12}>
                                <WastagesChart />
                            </Col> */}
                            <Col span={12}>
                                <PassRateChart />
                            </Col>
                            {/* <Col span={12}>
                                <NotGoodRateChart />
                            </Col> */}
                            <Col span={12}>
                                <QualityLineChart line='In'/>
                            </Col>
                            <Col span={12}>
                                <QualityLineChart line='Phủ'/>
                            </Col>
                            <Col span={12}>
                                <QualityLineChart line='Bế'/>
                            </Col>
                            <Col span={12}>
                                <QualityLineChart line='Bóc phôi'/>
                            </Col>
                            <Col span={12}>
                                <QualityLineChart line='Gấp dán'/>
                            </Col>
                            <Col span={12}>
                                <QualityLineChart line='Chọn'/>
                            </Col>
                            <Col span={12}>
                                <QualityLineChart line='OQC'/>
                            </Col>
                        </Row>
                    </Spin>
                </Col>
            </Row>

        </React.Fragment >
    );
};

export default KPI;
