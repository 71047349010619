import axios from "axios";
const prefix = 'p2/ui/';
//MaintenancePlans
export async function generateProductionPlan(params) {
    const res = await axios.post(prefix+'plan/generate', params );
    return res;
}
export async function createAutoProductionPlan(params) {
    const res = await axios.post(prefix+'plan/create', params );
    return res;
}