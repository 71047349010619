import { DatePicker, Col, Row, Card, Table, Button, Form, Select, Space, Typography, Divider, Layout } from 'antd';
import { DualAxes } from '@ant-design/charts';
import React, { useState, useEffect } from "react";
import { getLines, getPowerConsumeDataByMonth } from '../../../api/ui/main';
import dayjs from "dayjs";
import { exportThongSoMay } from '../../../api/ui/export';
import { baseURL } from '../../../config';

const { Sider } = Layout;
const { RangePicker } = DatePicker;

const PowerConsumeByMonth = (props) => {
    document.title = 'UI - Thông số máy'
    const [listLines, setListLines] = useState([]);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        page: 1,
        pageSize: 10,
        line_id: '',
        ca_sx: '',
        datetime: dayjs()
    });
    const [colTable, setColTable] = useState([]);
    const [sumPowerConsume, setSumPowerConsume] = useState(0);
    const [loading, setLoading] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);
    const [loSX, setLoSX] = useState();

    useEffect(() => {
        const month = [];
        for (let index = 1; index <= params.datetime.daysInMonth(); index++) {
            month.push({
                title: index.toString().padStart(2, '0'),
                key: index,
                dataIndex: index,
                align: 'center',
                render: (value) => value ?? "-",
            });
        }
        const columns = [
            {
                title: ' ',
                dataIndex: 'col',
                rowScope: 'row',
                width: '10%',
                fixed: 'left'
            },
            {
                title: 'Tháng ' + params.datetime.format('MM'),
                children: month
            }
        ];
        setColTable(columns);
    }, [params.datetime]);

    const btn_click = async () => {
        setLoading(true);
        const res = await getPowerConsumeDataByMonth(params);
        const filledData = fillMissingDates(res.data.data, params.datetime);
        setData(filledData);
        setSumPowerConsume(res.data.sum);
        setLoading(false);
    };

    useEffect(() => {
        btn_click();
        (async () => {
            const res = await getLines();
            const options = res.data.map(e => ({ ...e, value: e.id, label: e.name }));
            setListLines(options);
            const line_in = options.find(e => e.id === 10);
            if (line_in) setParams({ ...params, line_id: line_in.id });
        })();
    }, []);

    const exportFile = async () => {
        setExportLoading(true);
        const res = await exportThongSoMay({ ...params, lo_sx: loSX });
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading(false);
    };

    const onClickRow = async (record) => {
        setLoSX(record.lo_sx);
    };

    // Fill missing dates
    const fillMissingDates = (data, datetime) => {
        const daysInMonth = datetime.daysInMonth();
        const existingDataMap = data.reduce((acc, item) => {
            acc[item.col] = item;
            return acc;
        }, {});

        // Initialize "Tổng điện năng (kW)" if it doesn't exist
        if (!existingDataMap["Tổng điện năng (kW)"]) {
            existingDataMap["Tổng điện năng (kW)"] = { col: "Tổng điện năng (kW)" };
        }

        // Fill missing dates with null initially
        for (let i = 1; i <= daysInMonth; i++) {
            const dateStr = i;
            if (existingDataMap["Tổng điện năng (kW)"][dateStr] === undefined) {
                existingDataMap["Tổng điện năng (kW)"][dateStr] = null;
            }
        }

        // Update null values based on their neighbors
        for (let i = 1; i <= daysInMonth; i++) {
            const dateStr = i;
            if (existingDataMap["Tổng điện năng (kW)"][dateStr] === null) {
                const prevValue = i > 1 ? existingDataMap["Tổng điện năng (kW)"][(i - 1)] : null;
                const nextValue = i < daysInMonth ? existingDataMap["Tổng điện năng (kW)"][(i + 1)] : null;
                if ((prevValue !== null && prevValue > 0) || (nextValue !== null && nextValue > 0)) {
                    existingDataMap["Tổng điện năng (kW)"][dateStr] = 0;
                }
            }
        }

        return Object.values(existingDataMap);
    };


    // Transform the data for the line and column chart
    const transformDataForChart = (data) => {
        const result = [];
        const powerConsumeData = data.find(item => item.col === "Tổng điện năng (kW)");
        if (powerConsumeData) {
            Object.keys(powerConsumeData).forEach(key => {
                if (key !== 'col') {
                    result.push({ date: key, value: parseFloat(powerConsumeData[key]) });
                }
            });
        }
        return result;
    };

    const chartData = transformDataForChart(data);

    const config = {
        data: [chartData, chartData],
        xField: 'date',
        yField: ['value', 'value'],
        geometryOptions: [
            {
                geometry: 'column',
                color: '#2462a3',
                columnWidthRatio: 0.5,
            },
            {
                geometry: 'line',
                color: '#f88c24',
                lineStyle: {
                    lineWidth: 2,
                },
                point: {
                    size: 3,
                    shape: 'diamond',
                },
            },
        ],
        xAxis: {
            title: { text: 'Ngày' },
            label: {
                formatter: (val) => val.toString().padStart(2, '0')
            }
        },
        yAxis: {
            title: { text: 'Tổng điện năng (KWH)' },
            min: 0,
            label: {
                formatter: (v) => `${v} KWH`,
            }
        },
        legend: {
            custom: true,
            position: 'top',
            items: [
                {
                    value: 'value',
                    name: 'KWH',
                    marker: { symbol: 'square', style: { fill: '#2462a3', r: 5 } },
                },
                {
                    value: 'value',
                    name: 'KWH',
                    marker: { symbol: 'line', style: { stroke: '#f88c24', r: 5 } },
                },
            ],
        },
        height: '51vh',
    };

    return <>
        <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
            <Col span={4} className='custom-col'>
                <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                    <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                ]}>
                    <Divider>Điều kiện truy vấn</Divider>
                    <Form style={{ margin: '0 8px' }} layout="vertical">
                        <Form.Item label="Tháng" className='mb-3'>
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker picker='month' allowClear={false} placeholder='Chọn tháng' style={{ width: '100%' }} onChange={(value) => { setParams({ ...params, datetime: value }); setPage(1) }} value={params.datetime} />
                            </Space>
                        </Form.Item>
                        <Form.Item label="Công đoạn" className='mb-3'>
                            <Select
                                showSearch
                                value={params.line_id}
                                onChange={(value) => setParams({ ...params, line_id: value })}
                                placeholder="Chọn công đoạn"
                                options={listLines}
                            />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col span={20}>
                <Card title="Quản lý điện năng tiêu thụ">
                    <div style={{ height: '51vh' }}>
                        <DualAxes {...config} />
                    </div>
                    <Divider />
                    <Table size='small' bordered
                        loading={loading}
                        pagination={false}
                        scroll={
                            {
                                x: '150vw',
                                y: '40vh'
                            }
                        }
                        footer={() => <Typography.Text strong>{'Tổng điện năng tiêu thụ trong tháng (kW): ' + sumPowerConsume}</Typography.Text>}
                        columns={colTable}
                        dataSource={data} />
                </Card>
            </Col>
        </Row>
    </>
}

export default PowerConsumeByMonth;
