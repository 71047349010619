import Echo from 'laravel-echo';
import io from 'socket.io-client';
import { baseURL } from '../config';

window.io = io;

const echo = new Echo({
    // namespace: 'App.Events',
    broadcaster: 'socket.io',
    host: 'http://127.0.0.1:6001', // Địa chỉ của Laravel Echo Server
    transports: ['websocket', 'polling', 'flashsocket']
});

export default echo;
