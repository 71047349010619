
import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import highcharts3dModule from "highcharts/modules/exporting";
import axios from "axios";
import { baseURL } from "../../../config";

highcharts3d(Highcharts);
highcharts3dModule(Highcharts);

const NotGoodRateChart = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setData([
                    {
                        "type": "Tháng 2",
                        "value": 10.3,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 6.6,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 10,
                        "machine": "GL_637CIR"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 0.3,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 1.9,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 0.6,
                        "machine": "SN_UV"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 4.4,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 2.6,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 0.3,
                        "machine": "MK1060MF"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 1.1,
                        "machine": "ACE70CS"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 0,
                        "machine": "ACE70CS"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 0.1,
                        "machine": "ACE70CS"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 4,
                        "machine": "Trung bình"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 2.8,
                        "machine": "Trung bình"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 2.8,
                        "machine": "Trung bình"
                    }
                ]);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const machines = [...new Set(data.map(item => item.machine))];
        const series = [
            {
                name: "Tháng 2",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tháng 2");
                    return item ? item.value : 0;
                }),
            },
            {
                name: "Tháng 3",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tháng 3");
                    return item ? item.value : 0;
                }),
            },
            {
                name: "Tháng 4",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tháng 4");
                    return item ? item.value : 0;
                }),
            },
        ];
        const options = {
            chart: {
                height: 300,
                type: 'column',
                // options3d: {
                //     enabled: true,
                //     alpha: 20,
                //     beta: 0,
                //     viewDistance: 100,
                //     depth: 100
                // }
            },

            title: false,

            xAxis: {
                categories: machines,
                crosshair: true,
                accessibility: {
                    description: 'Máy'
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    }
                },
                borderRadius: 5,
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("ng-rate-chart", options);
    }, [data]);

    return <Card title="Tỷ lệ đạt thẳng"
        styles={{body: {padding: '0px'}}}
        style={
            { padding: '0px' }
        }>
        <div id="ng-rate-chart" />
    </Card>
};

export default NotGoodRateChart;