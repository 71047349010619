import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Layout, Table, Tag, Col, Row } from 'antd';
import ReactFullscreen from 'react-easyfullscreen';
import { FullscreenOutlined, FullscreenExitOutlined, CloseOutlined } from '@ant-design/icons';
import { getProductFMB } from '../../api/db/main';
import './THSX.css';
import { getProductionSituation, getProductionSituationLineGapDan, getProductionSituationLineIn } from '../../api/phase2/dashboard/main';

const colTable = [
    {
        title: 'MÁY',
        dataIndex: 'machine_name',
        key: 'machine_name',
        align: 'center',
    },
    {
        title: 'TÊN SẢN PHẨM',
        dataIndex: 'product',
        key: 'product',
        align: 'center',
    },
    {
        title: 'SL KẾ HOẠCH',
        dataIndex: 'sl_dau_ra_kh',
        key: 'sl_dau_ra_kh',
        align: 'center',
    },
    {
        title: 'MỤC TIÊU',
        dataIndex: 'sl_muc_tieu',
        key: 'sl_muc_tieu',
        align: 'center',
    },
    {
        title: 'SL THỰC TẾ',
        dataIndex: 'sl_thuc_te',
        key: 'sl_thuc_te',
        align: 'center',
    },
    {
        title: 'TỈ LỆ HOÀN THÀNH (%)',
        dataIndex: 'ti_le_ht',
        key: 'ti_le_ht',
        align: 'center',
    },
    {
        title: 'TỈ LỆ NG (%)',
        dataIndex: 'ti_le_ng',
        key: 'ti_le_ng',
        align: 'center',
    },
    {
        title: 'TÌNH TRẠNG',
        dataIndex: 'status',
        key: 'status',
        render: (value) => {
            var color = 'white';
            if (value == '1') color = 'orange'
            else if (value == '2') color = 'blue'
            else if (value == '3') color = 'green'
            else color = "#929292"
            return (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div style={{
                        width: '70px',
                        height: '70px',
                        background: color,
                        borderRadius: '50%'
                    }}>
                    </div>
                </div>
            );
        },
        align: 'center',
    },

];

const ProductionSituation1 = () => {
    const history = useHistory();
    const [isFullCreen, setIsFullScreen] = useState(false);
    const [clock, setClock] = useState(new Date())
    const loadData = async () => {
        const res1 = await getProductionSituationLineIn();
        setDataTable(res1.data.map(e => {
            return { ...e }
        }));
    }
    useEffect(() => {
        setInterval(() => tick(), 1000);
        loadData();
    }, [])
    const tick = () => {
        setClock(new Date());
    }

    const [dataTable, setDataTable] = useState([]);
    let interval;
    useEffect(() => {
        interval = setInterval(() => {
            loadData();
        }, 3000);
        return () => clearInterval(interval);
    }, [])
    var interval1;
    // useEffect(() => {
    //     interval = setInterval(async () => {
    //       history.push('/dashboard/canh-bao-bat-thuong');
    //     }, 30000);
    //     return () => clearInterval(interval1);
    // }, []);

    return (
        <React.Fragment>
            <Layout style={{ backgroundColor: '#e3eaf0', overflowX: 'hidden' }}>
                <Row className='w-100' style={{ height: 80, verticalAlign: 'center', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: '#2462a3', color: 'white' }}>
                    <div style={{ fontSize: '1.6em', fontWeight: '700', width: '15%', textAlign: 'center' }}>{clock.toLocaleString(['en-GB'], { hour12: false }).replace(',', '')}</div>
                    <div style={{ fontWeight: 700, fontSize: '2em', }}>TÌNH HÌNH SẢN XUẤT</div>
                    <Link to={'/screen'} style={{ margin: 'auto 0', width: '15%', textAlign: 'right' }}>
                        <CloseOutlined className='text-white' style={{ fontSize: '1.4em' }} />
                    </Link>
                </Row>
                <Row style={{ padding: '15px' }} gutter={[8, 8]}>
                    <Col span={24}>
                        <Table className='mt-3 table-thsx-gdlh'
                            bordered
                            pagination={false}
                            scroll={{ x: '100%', y: '100vh' }}
                            columns={colTable}
                            dataSource={dataTable}
                        />
                    </Col>
                </Row>
            </Layout>
            {/* )}
                </ReactFullscreen> */}

        </React.Fragment>
    );
};

export default ProductionSituation1;