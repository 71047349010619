import axios from "axios";

//Product
export async function getProduct(params) {
    const res = await axios.get('/product/list', { params });
    return res;
}
export async function createProduct(params) {
    const res = await axios.post('/product/create', params);
    return res;
}
export async function updateProduct(id, params) {
    const res = await axios.patch('/product/update/' + id, params);
    return res;
}
export async function deleteProduct(id) {
    const res = await axios.delete('/product/delete/' + id);
    return res;
}
export async function deleteMultipleProduct(params) {
    const res = await axios.post('/products/delete', params);
    return res;
}
export async function exportProduct(params) {
    const res = await axios.get('/product/export', { params });
    return res;
}