import { Card } from "antd";
import Highcharts from "highcharts";
import { useEffect } from "react";

const ErrorFrequency = ({ data = [] }) => {
    useEffect(() => {
        data.sort((a, b)=>{
            return b?.frequency - a?.frequency
        })
        const errors = [...new Set(data.map(item => item.name))];
        const value = data.map(item => item.frequency);
        const options = {
            chart: {
                type: 'column',
                height: 200
            },
            title: false,
            xAxis: [{
                categories: errors,
                crosshair: true
            }],
            yAxis: [{
                title: false,
                labels: {
                    format: '{value} lần',
                },
            }],
            plotOptions: {
                column: {
                    borderRadius: 0,
                    // depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y} lần'
                    }
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            series: [
                {
                    name: 'Xuất hiện',
                    data: value,

                },
            ],
            legend: false,
            exporting: false,
            credits: { enabled: false }
        };
        Highcharts.chart("error-frequency-chart", options);
    }, [data]);
    return (
        <Card title="Tần xuất xuất hiện lỗi" style={{ height: '100%', padding: '0px' }} styles={{ body: { padding: 12 } }}>
            <div id={"error-frequency-chart"} />
        </Card>
    )
}

export default ErrorFrequency;