import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import highcharts3dModule from "highcharts/modules/exporting";

highcharts3d(Highcharts);
highcharts3dModule(Highcharts);

const MachinePerformanceChart = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setData([
                    {
                        "type": "Tháng 1",
                        "value": 81,
                        "machine": "Gấp dán"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 66,
                        "machine": "Gấp dán"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 70,
                        "machine": "Gấp dán"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 65,
                        "machine": "Gấp dán"
                    },
                    {
                        "type": "Tháng 1",
                        "value": 45,
                        "machine": "In"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 36,
                        "machine": "In"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 72,
                        "machine": "In"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 40,
                        "machine": "In"
                    },
                    {
                        "type": "Tháng 1",
                        "value": 74,
                        "machine": "Liner"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 74,
                        "machine": "Liner"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 81,
                        "machine": "Liner"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 70,
                        "machine": "Liner"
                    },
                    {
                        "type": "Tháng 1",
                        "value": 86,
                        "machine": "Đục cắt"
                    },
                    {
                        "type": "Tháng 2",
                        "value": 65,
                        "machine": "Đục cắt"
                    },
                    {
                        "type": "Tháng 3",
                        "value": 74,
                        "machine": "Đục cắt"
                    },
                    {
                        "type": "Tháng 4",
                        "value": 79,
                        "machine": "Đục cắt"
                    }
                ]);
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, []);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const machines = [...new Set(data.map(item => item.machine))];
        const series = [
            {
                name: "Tháng 6",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tháng 1");
                    return item ? item.value : 0;
                }),
            },
            {
                name: "Tháng 7",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tháng 2");
                    return item ? item.value : 0;
                }),
            },
            {
                name: "Tháng 8",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tháng 3");
                    return item ? item.value : 0;
                }),
            },
            // {
            //     name: "Tháng 4",
            //     data: machines.map(machine => {
            //         const item = data.find(d => d.machine === machine && d.type === "Tháng 4");
            //         return item ? item.value : 0;
            //     }),
            // },
        ];
        const options = {
            chart: {
                height: 300,
                type: 'column',
                // options3d: {
                //     enabled: true,
                //     alpha: 20,
                //     beta: 0,
                //     viewDistance: 100,
                //     depth: 100
                // }
            },

            title: false,

            xAxis: {
                categories: machines,
                crosshair: true,
                accessibility: {
                    description: 'Máy'
                },
                labels: {
                    style: {
                        fontSize: '12px'
                    }
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}%'
                    },
                    borderRadius: 0,
                }
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("productivity-chart", options);
    }, [data]);

    return <Card title="Hiệu suất sử dụng thiết bị"
        styles={{body: {padding: '0px'}}}
        style={
            { padding: '0px' }
        }>
        <div id="productivity-chart" />
    </Card>
};

export default MachinePerformanceChart;