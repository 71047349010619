import React, { useState, useEffect } from 'react';
import { DatePicker, Col, Row, Card, Table, Layout, Divider, Button, Form, Select, Space, Spin, Tree, Radio, Empty } from 'antd';
import "../style.scss";
import {
    getLines, getCustomers, getProduceHistory, getDataFilterUI,
    getTreeLines
} from '../../../api/ui/main';
import { exportProduceHistory, exportReportProduceHistory } from '../../../api/ui/export';
import { baseURL } from '../../../config';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from "dayjs";
import 'dayjs/locale/vi';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isoWeek from "dayjs/plugin/isoWeek";
import weekday from "dayjs/plugin/weekday";
import { getProductionHistory, getTreeSelect } from '../../../api/phase2/ui/main';
import CalculateTableHeight from '../../../components/calculateTableHeight';
dayjs.extend(isoWeek);
dayjs.extend(weekday);
dayjs.extend(customParseFormat);
const { Sider } = Layout;
const { RangePicker } = DatePicker;
const LichSuSanXuat = (props) => {
    document.title = "UI - Lịch sử sản xuất";
    const [listLines, setListLines] = useState([]);
    const [listNameProducts, setListNameProducts] = useState([]);
    const [listLoSX, setListLoSX] = useState([]);
    const [listCustomers, setListCustomers] = useState([]);
    const [selectedLine, setSelectedLine] = useState();
    const [totalPage, setTotalPage] = useState(1);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [params, setParams] = useState({
        date: [dayjs(), dayjs()]
    });
    const [dateType, setDateType] = useState('date');
    const columns1 = [
        {
            title: 'Số lượng đầu ra (kế hoạch)',
            dataIndex: 'sl_dau_ra_kh',
            key: 'sl_dau_ra_kh',
            align: 'center',
        },
        {
            title: 'Số lượng đầu ra OK (thực tế)',
            dataIndex: 'sl_dau_ra_thuc_te_ok',
            key: 'sl_dau_ra_thuc_te_ok',
            align: 'center',
        },
        {
            title: 'Chênh lệch thực tế - kế hoạch',
            dataIndex: 'chenh_lech',
            key: 'chenh_lech',
            align: 'center',
        },
        {
            title: 'Tỷ lệ hoàn thành',
            dataIndex: 'ty_le',
            key: 'ty_le',
            align: 'center',
        },
        {
            title: 'Số lượng tem vàng',
            dataIndex: 'sl_tem_vang',
            key: 'sl_tem_vang',
            className: 'yellow',
            align: 'center',
        },
        {
            title: 'Số lượng NG',
            dataIndex: 'sl_ng',
            key: 'sl_ng',
            className: 'red',
            align: 'center',
        },
    ];

    const columns2 = [
        {
            title: 'Lô SX',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
        },
    ]

    const newColumns2 = [
        ...columns2,
        ...(listLines[0]?.children ?? [])?.map(e => {
            return {
                title: e.title,
                dataIndex: e.key,
                key: e.key,
                align: 'center',
            }
        })
    ];


    const columns3 = [
        {
            title: 'STT',
            dataIndex: 'index',
            key: 'index',
            align: 'center',
            fixed: 'left',
            render: (value, record, index) => ((page - 1) * pageSize) + index + 1,
        },
        {
            title: 'Tên sản phẩm',
            dataIndex: 'ten_san_pham',
            key: 'ten_san_pham',
            align: 'center',
            fixed: 'left'
        },
        {
            title: 'Ngày sản xuất',
            dataIndex: 'ngay_sx',
            key: 'ngay_sx',
            align: 'center',
        },
        {
            title: 'Công đoạn',
            dataIndex: 'cong_doan',
            key: 'cong_doan',
            align: 'center',
        },
        {
            title: 'Máy sản xuất',
            dataIndex: 'machine',
            key: 'machine',
            align: 'center',
        },
        {
            title: 'Khách hàng',
            dataIndex: 'khach_hang',
            key: 'khach_hang',
            align: 'center',
        },
        {
            title: 'Mã hàng',
            dataIndex: 'product_id',
            key: 'product_id',
            align: 'center',
        },
        {
            title: 'Lô sản xuất',
            dataIndex: 'lo_sx',
            key: 'lo_sx',
            align: 'center',
        },
        {
            title: 'Mã pallet/thùng',
            dataIndex: 'lot_id',
            key: 'lot_id',
            align: 'center',
        },
        {
            title: 'Kế hoạch',
            children: [
                {
                    title: 'Thời gian bắt đầu',
                    dataIndex: 'thoi_gian_bat_dau_kh',
                    key: 'thoi_gian_bat_dau_kh',
                    align: 'center',
                },
                {
                    title: 'Thời gian kết thúc',
                    dataIndex: 'thoi_gian_ket_thuc_kh',
                    key: 'thoi_gian_ket_thuc_kh',
                    align: 'center',
                },
                {
                    title: 'Số lượng đầu vào',
                    dataIndex: 'sl_dau_vao_kh',
                    key: 'sl_dau_vao_kh',
                    align: 'center',
                },
                {
                    title: 'Số lượng đầu ra',
                    dataIndex: 'sl_dau_ra_kh',
                    key: 'sl_dau_ra_kh',
                    align: 'center',
                },
            ]
        },
        {
            title: 'Thực tế',
            children: [
                {
                    title: 'Thời gian bắt đầu',
                    dataIndex: 'thoi_gian_bat_dau',
                    key: 'thoi_gian_bat_dau',
                    align: 'center',
                },
                {
                    title: 'Thời gian kết thúc',
                    dataIndex: 'thoi_gian_ket_thuc',
                    key: 'thoi_gian_ket_thuc',
                    align: 'center',
                },
                {
                    title: 'Thời gian chạy sản lượng',
                    dataIndex: 'thoi_gian_chay_san_luong',
                    key: 'thoi_gian_chay_san_luong',
                    align: 'center',
                },
                {
                    title: 'Số lượng đầu vào thực tế',
                    dataIndex: 'sl_dau_vao_hang_loat',
                    key: 'sl_dau_vao_hang_loat',
                    align: 'center',
                },
                {
                    title: 'Số lượng đầu ra thực tế',
                    dataIndex: 'sl_dau_ra_hang_loat',
                    key: 'sl_dau_ra_hang_loat',
                    align: 'center',
                },
                {
                    title: 'Số lượng đầu ra OK',
                    dataIndex: 'sl_dau_ra_ok',
                    key: 'sl_dau_ra_ok',
                    align: 'center',
                },
                {
                    title: 'Số lượng tem vàng',
                    dataIndex: 'sl_tem_vang',
                    key: 'sl_tem_vang',
                    align: 'center',
                },
                {
                    title: 'Số lượng NG',
                    dataIndex: 'sl_ng',
                    key: 'sl_ng',
                    align: 'center',
                },
                {
                    title: 'Điện năng tiêu thụ',
                    dataIndex: 'dien_nang',
                    key: 'dien_nang',
                    align: 'center',
                },
            ]
        },
    ];

    useEffect(() => {
        (async () => {
            const res1 = await getTreeSelect();
            setListLines(res1.data);
            const res5 = await getCustomers();
            setListCustomers(res5.data.map(e => {
                return { ...e, label: e.name, value: e.id }
            }));

        })()
    }, [])
    useEffect(() => {
        (async () => {
            var res = await getDataFilterUI({ khach_hang: params.khach_hang });
            if (res.success) {
                setListNameProducts(res.data.product.map(e => {
                    return { ...e, label: e.name, value: e.id }
                }));
                setListLoSX(Object.values(res.data.lo_sx).map(e => {
                    return { label: e, value: e }
                }));
            }
        })()
    }, [params.khach_hang])

    const [dataTable1, setDataTable1] = useState([{
        chenh_lech: 0,
        sl_dau_ra_kh: 0,
        sl_dau_ra_thuc_te_ok: 0,
        sl_ng: 0,
        sl_tem_vang: 0,
        ty_le: 0
    }]);
    const [dataTable2, setDataTable2] = useState([]);
    const [dataTable3, setDataTable3] = useState([]);

    const loadDataTable = async (params) => {
        setLoading(true);
        const res1 = await getProductionHistory(params);
        let data = res1.data;
        setTotalPage(data.totalPage);
        setDataTable1([{
            chenh_lech: data.overall['sl_chenh_lech'],
            sl_dau_ra_kh: data.overall['sl_dau_ra_kh'],
            sl_dau_ra_thuc_te_ok: data.overall['sl_dau_ra_thuc_te_ok'],
            sl_ng: data.overall['sl_ng'],
            sl_tem_vang: data.overall['sl_tem_vang'],
            ty_le: data.overall['ty_le'],
        }]);

        setDataTable2(Object.keys(data.percent).map((item, i) => {
            return {
                lo_sx: item,
                ...data.percent[item],
            }
        }
        ));

        setDataTable3(data.table.map(e => {
            return {
                ...e,
            }
        }));
        setLoading(false);
    }

    function btn_click(page = 1, pageSize = 10) {
        setPage(page);
        setPageSize(pageSize);
        loadDataTable({ ...params, page, pageSize });
    }
    useEffect(() => {
        btn_click();
    }, [])
    const [exportLoading1, setExportLoading1] = useState(false);
    const [exportLoading2, setExportLoading2] = useState(false);
    const exportFile = async () => {
        setExportLoading1(true);
        const res = await exportProduceHistory(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading1(false);
    }
    const [loading, setLoading] = useState(false)
    const reportProduceHistory = async () => {
        setExportLoading2(true);
        const res = await exportReportProduceHistory(params);
        if (res.success) {
            window.location.href = baseURL + res.data;
        }
        setExportLoading2(false);
    }
    const onCheck = (checkedKeys, info) => {
        const selectKeys = [];
        if (info.node.type === 'factory') {
            if (info.checked) {
                info.node.children.map(e => {
                    selectKeys.push(e.key?.toString())
                })
            }
        } else {
            info.checkedNodes.map(e => {
                switch (e.type) {
                    case 'line':
                        selectKeys.push(e.key?.toString());
                        break;
                    case 'machine':
                        selectKeys.push(e.line_id?.toString());
                        break;
                    default:
                        break;
                }
            })
        }
        setParams({ ...params, line_id: [...new Set(selectKeys)] })
    };
    const customDateFormat = (value) => {
        switch (dateType) {
            case 'week':
                return (`Tuần ${dayjs(value).format('WW - GGGG')}`);
                break;
            case 'month':
                return (`Tháng ${dayjs(value).format('MM')} - ${dayjs(value).format('YYYY')}`);
                break;
            case 'year':
                return (`Năm ${dayjs(value).format('YYYY')}`);
                break;
            default:
                return (dayjs(value).format('DD/MM/YYYY'));
                break;
        }
    }
    useEffect(() => {
        onChangeDate('start_date', params.date[0], dateType);
        onChangeDate('end_date', params.date[1], dateType);
    }, [dateType])
    const onChangeDate = (position, value, dateType) => {
        switch (dateType) {
            case 'week':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('isoWeek'), dayjs(params.date[1]).endOf('isoWeek')] });
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('isoWeek'), dayjs(value).endOf('isoWeek')] });
                }
                break;
            case 'month':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('month'), dayjs(params.date[1]).endOf('month')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('month'), dayjs(value).endOf('month')] });
                }
                break;
            case 'year':
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value).startOf('year'), dayjs(params.date[1]).endOf('year')] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]).startOf('year'), dayjs(value).endOf('year')] });
                }
                break;
            default:
                if (position === 'start_date') {
                    setParams({ ...params, date: [dayjs(value), dayjs(params.date[1])] })
                } else {
                    setParams({ ...params, date: [dayjs(params.date[0]), dayjs(value)] });
                }
                break;
        }
    }

    const cardHeight = CalculateTableHeight('container');
    console.log(cardHeight);
    return (
        <React.Fragment>
            <Row style={{ padding: '8px' }} gutter={[8, 8]} className='custom-row'>
                <Col span={4} className='custom-col'>
                    <Card bodyStyle={{ padding: 0 }} className='custom-ant-card' actions={[
                        <Button type="primary" style={{ width: "90%" }} onClick={() => btn_click()}>Truy vấn</Button>
                    ]}>
                        <Form style={{ margin: '0 8px' }} layout="vertical">
                            <Divider>Công đoạn</Divider>
                            <Form.Item className='mb-3'>
                                {
                                    listLines.length ?
                                        <Tree
                                            defaultExpandedKeys={[2]}
                                            style={{ maxHeight: 250, overflowY: 'auto' }}
                                            checkable
                                            selectable={false}
                                            onCheck={onCheck}
                                            treeData={listLines}
                                        />
                                        :
                                        <LoadingOutlined />
                                }
                            </Form.Item>
                            <Divider>Thời gian truy vấn</Divider>
                            <Radio.Group
                                options={[
                                    { value: 'date', label: 'Ngày', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'week', label: 'Tuần', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'month', label: 'Tháng', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                    { value: 'year', label: 'Năm', style: { width: '25%', justifyContent: 'center', display: 'flex' } },
                                ]}
                                className='d-flex justify-content-center mb-2 w-100'
                                onChange={(e) => setDateType(e.target.value)}
                                value={dateType}
                                optionType="button"
                                buttonStyle="solid"
                            />
                            <Space direction='vertical' style={{ width: '100%' }}>
                                <DatePicker allowClear={false} placeholder='Bắt đầu' style={{ width: '100%' }} onChange={(value) => onChangeDate('start_date', value, dateType)} value={params.date[0]} format={customDateFormat} picker={dateType} />
                                <DatePicker allowClear={false} placeholder='Kết thúc' style={{ width: '100%' }} onChange={(value) => onChangeDate('end_date', value, dateType)} value={params.date[1]} format={customDateFormat} picker={dateType} />
                            </Space>
                            <Divider>Điều kiện truy vấn</Divider>
                            <Form.Item label="Khách hàng" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Chọn khách hàng"
                                    onChange={(value) => { setParams({ ...params, khach_hang: value }); }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    popupMatchSelectWidth={listCustomers.length ? 400 : "100%"}
                                    options={listCustomers}
                                />
                            </Form.Item>
                            <Form.Item label="Tên sản phẩm" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    onChange={(value) => {
                                        setParams({ ...params, ten_sp: value });
                                    }}
                                    placeholder="Nhập tên sản phẩm"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={listNameProducts}
                                />
                            </Form.Item>
                            <Form.Item label="Lô Sản xuất" className='mb-3'>
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Nhập lô sản xuất"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    onChange={(value) => {
                                        setParams({ ...params, lo_sx: value });
                                    }}
                                    options={listLoSX}
                                />
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={20} className='custom-col'>
                    <Card style={{ height: '100%' }} title="Lịch sử sản xuất" extra={
                        <Space>
                            <Button type="primary" onClick={reportProduceHistory} loading={exportLoading2}>Báo cáo truy vấn</Button>
                            <Button type="primary" onClick={exportFile} loading={exportLoading1}>Xuất Excel</Button>
                        </Space>
                    }>
                        <div className='container d-flex flex-column gap-3' style={{ height: CalculateTableHeight('container') }}>
                            <Card styles={{ body: { padding: 0 } }} bordered={false}>
                                <Table size='small' bordered
                                    pagination={false}
                                    columns={columns1}
                                    dataSource={dataTable1} />
                            </Card>
                            <Card styles={{ body: { padding: 0 } }} bordered={false}>
                                <Table size='small' bordered
                                    locale={{emptyText: <Empty style={{height: '100vh'}} image={null} description={null}/>}}
                                    pagination={false}
                                    scroll={
                                        {
                                            x: '100%',
                                            y: '15vh'
                                        }
                                    }
                                    columns={newColumns2}
                                    dataSource={dataTable2} />
                            </Card>
                            <Table size='small' bordered
                                className="table-lssx"
                                locale={{emptyText: <Empty style={{height: '100vh'}} image={null} description={null}/>}}
                                pagination={{
                                    current: page,
                                    size: 'default',
                                    total: totalPage,
                                    onChange: (page, pageSize) => {
                                        btn_click(page, pageSize);
                                    }
                                }}
                                scroll={
                                    {
                                        x: '150vw',
                                        y: `calc(${cardHeight/2}px - 7vh)`
                                    }
                                }
                                columns={columns3}
                                dataSource={dataTable3} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default LichSuSanXuat;