import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Layout, Table, Tag, Col, Row, Card } from 'antd';
import ReactFullscreen from 'react-easyfullscreen';
import { FullscreenOutlined, FullscreenExitOutlined, CloseOutlined } from '@ant-design/icons';
import { getProductFMB } from '../../api/db/main';
import './THSX.css';
import { getProductionSituation, getProductionSituationByMachine } from '../../api/phase2/dashboard/main';
import CalculateTableHeight from '../../components/calculateTableHeight';

const colTable = [
    {
        title: 'MÁY',
        dataIndex: 'machine_name',
        key: 'machine_name',
        align: 'center',
    },
    {
        title: 'TÊN SẢN PHẨM',
        dataIndex: 'product',
        key: 'product',
        align: 'center',
    },
    {
        title: 'SL KẾ HOẠCH',
        dataIndex: 'sl_dau_ra_kh',
        key: 'sl_dau_ra_kh',
        align: 'center',
    },
    {
        title: 'MỤC TIÊU',
        dataIndex: 'sl_muc_tieu',
        key: 'sl_muc_tieu',
        align: 'center',
    },
    {
        title: 'SL THỰC TẾ',
        dataIndex: 'sl_thuc_te',
        key: 'sl_thuc_te',
        align: 'center',
    },
    {
        title: 'TỈ LỆ HOÀN THÀNH (%)',
        dataIndex: 'ti_le_ht',
        key: 'ti_le_ht',
        align: 'center',
    },
    {
        title: 'TỈ LỆ NG (%)',
        dataIndex: 'ti_le_ng',
        key: 'ti_le_ng',
        align: 'center',
    },
    {
        title: 'TÌNH TRẠNG',
        dataIndex: 'status',
        key: 'status',
        render: (value) => {
            var color = 'white';
            if (value == '1') color = 'orange'
            else if (value == '2') color = 'blue'
            else if (value == '3') color = 'green'
            else color = "#929292"
            return (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <div style={{
                        width: '70px',
                        height: '70px',
                        background: color,
                        borderRadius: '50%'
                    }}>
                    </div>
                </div>
            );
        },
        align: 'center',
    },

];

const ProductionSituation2 = () => {
    const [clock, setClock] = useState(new Date());
    const dataRef = useRef([]);
    const [tableHeight, setTableHeight] = useState('100%');
    const loadData = async () => {
        const res1 = await getProductionSituationByMachine({ordering_machine: (dataRef.current??[]).map(e => e.machine_code).join(',')});
        setDataTable(res1.data.map((e, index) => {
            if(index === (res1.data??[])?.length - 1){
                setSpecifiedRowKey(index);
            }
            return { ...e, key: index }
        }));
    }
    useEffect(() => {
        setInterval(() => tick(), 1000);
        loadData();
    }, [])
    const tick = () => {
        setClock(new Date());
    }

    const [dataTable, setDataTable] = useState([]);
    let interval;
    useEffect(() => {
        interval = setInterval(() => {
            loadData();
        }, 3000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        console.log(dataTable.length, dataRef.current);
        if (dataTable.length != dataRef.current?.length) {
            setTableHeight(CalculateTableHeight('table-db'));
        }
        dataRef.current = dataTable;
    }, [dataTable]);

    const tableRef = useRef();
    const [specifiedRowKey, setSpecifiedRowKey] = useState(null);
    const handleScrollToRow = () => {
        if (specifiedRowKey !== null && tableRef.current) {
            tableRef.current?.scrollTo({ key: specifiedRowKey });
        }
    };
    useEffect(() => {
        if (dataTable.length > 0) {
            handleScrollToRow();
        }
    }, [specifiedRowKey, dataTable]);

    return (
        <React.Fragment>
            <Layout style={{ backgroundColor: '#e3eaf0', overflowX: 'hidden' }}>
                <Row className='w-100' style={{ height: 80, verticalAlign: 'center', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between', padding: '10px', backgroundColor: '#2462a3', color: 'white' }}>
                    <div style={{ fontSize: '1.6em', fontWeight: '700', width: '15%', textAlign: 'center' }}>{clock.toLocaleString(['en-GB'], { hour12: false }).replace(',', '')}</div>
                    <div style={{ fontWeight: 700, fontSize: '2em', }}>TÌNH HÌNH SẢN XUẤT</div>
                    <Link to={'/screen'} style={{ margin: 'auto 0', width: '15%', textAlign: 'right' }}>
                        <CloseOutlined className='text-white' style={{ fontSize: '1.4em' }} />
                    </Link>
                </Row>
                <Card title={null} styles={{ body: { padding: 0, height: '100%', backgroundColor: 'transparent' } }} style={{ height: 'calc(100vh - 80px - 32px)', margin: 16, backgroundColor: 'transparent' }}>
                    <Table className='table-db'
                        bordered
                        pagination={false}
                        scroll={{ y: CalculateTableHeight('table-db') }}
                        columns={colTable}
                        dataSource={dataTable}
                    />
                </Card>
            </Layout>
            {/* )}
                </ReactFullscreen> */}

        </React.Fragment>
    );
};

export default ProductionSituation2;