
import { Column } from "@antv/g2plot";
import { Card } from "antd";
import { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import highcharts3d from "highcharts/highcharts-3d";
import highcharts3dModule from "highcharts/modules/exporting";
import axios from "axios";
import { baseURL } from "../../../config";

highcharts3d(Highcharts);
highcharts3dModule(Highcharts);

const ProductivityChart = ({params}) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                if(params){
                    // var res = axios.get(baseURL+"/api/kpi/productivity", {params});
                    // console.log(res);
                    setData([
                        {
                            "type": "Đầu vào",
                            "value": 508742,
                            "machine": "Gấp dán"
                        },
                        {
                            "type": "Hàng OK",
                            "value": 347624,
                            "machine": "Gấp dán"
                        },
                        {
                            "type": "Tem vàng",
                            "value": 110400,
                            "machine": "Gấp dán"
                        },
                        {
                            "type": "NG",
                            "value": 50718,
                            "machine": "Gấp dán"
                        },
                        {
                            "type": "Đầu vào",
                            "value": 227622,
                            "machine": "In"
                        },
                        {
                            "type": "Hàng OK",
                            "value": 130906,
                            "machine": "In"
                        },
                        {
                            "type": "Tem vàng",
                            "value": 95256,
                            "machine": "In"
                        },
                        {
                            "type": "NG",
                            "value":  1460,
                            "machine": "In"
                        },
                        {
                            "type": "Đầu vào",
                            "value":  457078,
                            "machine": "Liner"
                        },
                        {
                            "type": "Hàng OK",
                            "value": 341404,
                            "machine": "Liner"
                        },
                        {
                            "type": "Tem vàng",
                            "value": 114264,
                            "machine": "Liner"
                        },
                        
                        {
                            "type": "NG",
                            "value": 1410,
                            "machine": "Liner"
                        },
                        {
                            "type": "Đầu vào",
                            "value": 379484,
                            "machine": "Đục cắt"
                        },
                        {
                            "type": "Hàng OK",
                            "value": 260352,
                            "machine": "Đục cắt"
                        },
                        {
                            "type": "Tem vàng",
                            "value": 118643,
                            "machine": "Đục cắt"
                        },
                        {
                            "type": "NG",
                            "value": 489,
                            "machine": "Đục cắt"
                        }
                    ]);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [params]);
    useEffect(() => {
        if (data.length === 0) {
            return;
        }
        const machines = [...new Set(data.map(item => item.machine))];
        const series = [
            {
                name: "Đầu vào",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Đầu vào");
                    return item ? item.value : 0;
                }),
                color: '#1f77b4',
            },
            {
                name: "Hàng OK",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Hàng OK");
                    return item ? item.value : 0;
                }),
                color: '#2ca02c',
            },
            {
                name: "Tem vàng",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "Tem vàng");
                    return item ? item.value : 0;
                }),
                color: '#fff80e',
            },
            {
                name: "NG",
                data: machines.map(machine => {
                    const item = data.find(d => d.machine === machine && d.type === "NG");
                    return item ? item.value : 0;
                }),
                color: '#d62728',
            },
        ];
        const options = {
            chart: {
                height: 300,
                type: 'column',
                // options3d: {
                //     enabled: true,
                //     alpha: 20,
                //     beta: 0,
                //     viewDistance: 100,
                //     depth: 100
                // }
            },

            title: false,
            xAxis: {
                categories: machines,
                crosshair: true,
                accessibility: {
                    description: 'Máy'
                }
            },
            plotOptions: {
                column: {
                    depth: 40,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}'
                    },
                    borderRadius: 0,
                }
            },

            yAxis: {
                title: null,
            },
            series: series,
            exporting: false,
            credits: { enabled: false }
        };

        Highcharts.chart("machine-performance-chart", options);
    }, [data]);

    return <Card title="Năng suất"
        styles={{body: {padding: '0px'}}}
        style={
            { padding: '0px' }
        }>
        <div id="machine-performance-chart" />
    </Card>
};

export default ProductivityChart;