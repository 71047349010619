import axios from "axios";

//ProductionJourney
export async function getProductionJourney(params) {
    const res = await axios.get('/product/list', { params });
    return res;
}
export async function createProductionJourney(params) {
    const res = await axios.post('/product/create', params);
    return res;
}
export async function updateProductionJourney(id, params) {
    const res = await axios.patch('/product/update/' + id, params);
    return res;
}
export async function deleteProductionJourney(id) {
    const res = await axios.delete('/product/delete/' + id);
    return res;
}
export async function deleteMultipleProductionJourney(params) {
    const res = await axios.post('/products/delete', params);
    return res;
}
export async function exportProductionJourney(params) {
    const res = await axios.get('/product/export', { params });
    return res;
}